import { create } from "zustand";

interface UiStore {
  showCartCreateModal: boolean;
  showCartUpdateModal: boolean;
  showCartDetailModal: boolean;
  showCartListModal: boolean;
  showCartDuplicateModal: boolean;
  requestReload: boolean;
  cartToDuplicateData:
    | undefined
    | {
        id: number | undefined;
        name: string | undefined;
        comments: string | undefined;
      };
  cartToDeleteData: { id: number; name: string } | undefined;
  api: {
    toggleShowCartCreateModal: () => void;
    toggleShowCartUpdateModal: () => void;
    toggleShowCartDetailModal: () => void;
    toggleShowCartListModal: () => void;
    toggleShowCartDuplicateModal: (data?: {
      id: number;
      name: string;
      comments: string | undefined;
    }) => void;
    setCartToDeleteData: (data?: { id: number; name: string }) => void;
    setRequestReload: (state: boolean) => void;
  };
}

export const useUiStore = create<UiStore>((set, get) => ({
  showCartCreateModal: false,
  showCartUpdateModal: false,
  showCartDeleteModal: false,
  showCartDetailModal: false,
  showCartListModal: false,
  showCartDuplicateModal: false,
  cartToDeleteData: undefined,
  cartToDuplicateData: undefined,
  requestReload: false,
  api: {
    toggleShowCartCreateModal: () =>
      set(() => ({ showCartCreateModal: !get().showCartCreateModal })),
    toggleShowCartUpdateModal: () =>
      set(() => ({ showCartUpdateModal: !get().showCartUpdateModal })),
    toggleShowCartDetailModal: () =>
      set(() => ({ showCartDetailModal: !get().showCartDetailModal })),
    toggleShowCartListModal: () =>
      set(() => ({ showCartListModal: !get().showCartListModal })),
    toggleShowCartDuplicateModal: (data) =>
      set(() => {
        return {
          showCartDuplicateModal: !get().showCartDuplicateModal,
          cartToDuplicateData: data,
        };
      }),
    setCartToDeleteData: (cartToDeleteData) =>
      set(() => ({ cartToDeleteData })),
    setRequestReload: (state) => set(() => ({ requestReload: state })),
  },
}));
