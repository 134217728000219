import { useState } from "react";
import RegistrationForm from "./RegistrationForm";
import { RegistrationState } from "./types";
import SuccessfulRegistration from "./SuccessfulRegistration";

const RegisterPage = ({
  setShowRegisterPage,
}: {
  setShowRegisterPage: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [registrationState, setRegistrationState] = useState(
    RegistrationState.NotRegistered
  );

  switch (registrationState) {
    case RegistrationState.NotRegistered:
      return <RegistrationForm setRegistrationState={setRegistrationState} />;

    case RegistrationState.RegistrationSuccessful:
      return (
        <SuccessfulRegistration setShowRegisterPage={setShowRegisterPage} />
      );
  }
};

export default RegisterPage;
