import { Row } from "react-bootstrap";
import { useDataStore } from "../../store/dataStore";
import { Brand } from "../../types";
import { useState } from "react";
import GenericCard from "../Generic/GenericCard/GenericCard";
import { Page } from "../../constants";

const EXTENSIONS = ["png", "jpeg"];

const BrandCard = ({ brand }: { brand: Brand }) => {
  const [currentExtensionIndex, setCurrentExtensionIndex] = useState(0);
  const [noImageFound, setNoImageFound] = useState(false);

  const handleError = () => {
    if (currentExtensionIndex < EXTENSIONS.length - 1) {
      setCurrentExtensionIndex(currentExtensionIndex + 1);
    } else {
      setNoImageFound(true);
    }
  };

  const imagePath = `/img/brands/${brand.name
    .toLowerCase()
    .replaceAll(" ", "")}.${EXTENSIONS[currentExtensionIndex]}`;
  if (noImageFound) {
    return <></>;
  }

  return (
    <GenericCard
      img={imagePath}
      link={`/${Page.Brands}/${brand.id}`}
      onImgError={handleError}
      style={{ width: "16rem", margin: 2 }}
      variant="secondary"
      height={200}
    />
  );
};

const BrandGrid = () => {
  const { brands } = useDataStore();

  return (
    <Row>
      {brands.map((brand) => (
        <BrandCard key={`brand-card-${brand.id}`} brand={brand} />
      ))}
    </Row>
  );
};

export default BrandGrid;
