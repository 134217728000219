import { parsePrice } from "../../Helper";
import { ProductPrice } from "../../types";

const ProductPriceRenderer = ({
  productPrice,
  quantity = 1,
}: {
  productPrice?: ProductPrice;
  quantity?: number;
}) => {
  if (!productPrice) {
    return <div>-</div>;
  }
  return <div>{parsePrice(productPrice, quantity)}</div>;
};

export default ProductPriceRenderer;
