import { create } from "zustand";
import { Brand, Cart, EntityType } from "../types";
import { getList } from "../components/djangoHelpers";

interface DataStore {
  totalCartCount: number;
  recentCarts: Array<Cart>;
  brands: Array<Brand>;
  api: {
    loadRecentCarts: () => void;
    loadBrands: () => void;
  };
}

export const useDataStore = create<DataStore>((set) => ({
  totalCartCount: 0,
  recentCarts: [],
  brands: [],
  api: {
    loadRecentCarts: () => {
      getList<Cart>(EntityType.Carts, false,false, undefined).then((data) =>{
        console.log(data)
        return set(() => ({ recentCarts: data.results, totalCartCount: data.count }))
      }
      );
    },
    loadBrands: () => {
      getList<Brand>(EntityType.Brands, false, false,undefined).then((data) =>
        set(() => ({ brands: data }))
      );
    },
  },
}));
