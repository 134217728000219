import { DetProduct } from "../../types";
import { useDetailViewContext } from "../../contexts/DetailViewContext";

const ProductDetail = () => {
  const { entity: product } = useDetailViewContext<DetProduct>();
  if (product === undefined) return <p>Cargando ...</p>;
  return (
    <div className="container">
      <div className="row">
        <div className="jumbotron col-12">
          <p>{product.sku}</p>
          <h1 className="display-3">{product.name}</h1>
          <p className="lead">{product.brand.name}</p>
          <p className="lead">{product.productPrice?.peso_price}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
