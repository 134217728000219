import React from "react";
import { DisplayedField, EntityType, Filter, FilterType } from "../../types";
import PaginationContainer from "../Generic/PaginationContainer";
import EntityListProvider from "../../contexts/entityListContetxt";
import { DataType } from "../Editors/Input";

const PRODUCT_FILTER_TYPES: FilterType[] = [
  {
    value: "all",
    name: "Todo",
  },
  {
    value: "sku",
    name: "SKU",
  },
  {
    value: "name",
    name: "Nombre",
  },
  {
    value: "brand__name",
    name: "Marca",
  },
];

const PRODUCT_DISPLAYED_FIELDS: DisplayedField[] = [
  { field: "sku", label: "SKU" },
  { field: "name", label: "Descripcion", isLink: true },
  { field: "brand.name", label: "Marca" },
  { field: "productPrice", label: "Precio", dataType: DataType.ProductPrice },
];

// {x: 'inbrand', value: string}

const ProductList = ({ staticFilters }: { staticFilters?: Filter[] }) => {
  return (
    <div style={{ width: "100%" }}>
      <EntityListProvider
        entityType={EntityType.Products}
        staticFilters={staticFilters}
      >
        <PaginationContainer
          filterTypes={PRODUCT_FILTER_TYPES}
          displayedFields={PRODUCT_DISPLAYED_FIELDS}
        />
      </EntityListProvider>
    </div>
  );
};

export default ProductList;
