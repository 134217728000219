import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { EntityType, Entity } from "../types";
import { getOne } from "../components/djangoHelpers";
import { useParams } from "react-router-dom";
import { ENTITY_TYPE_TO_FRONTEND_NAME } from "../constants";

interface DetailViewContextInterface<E extends Entity> {
  entity: E;
  setEntityData: (entity: E | undefined) => void;
}

// Generic context creation
const DetailViewContext =
  React.createContext<DetailViewContextInterface<any> | null>(null);

export const useDetailViewContext = <E extends Entity>() => {
  const context = useContext(
    DetailViewContext as React.Context<DetailViewContextInterface<E> | null>
  );
  if (!context)
    throw new Error(
      "useDetailViewContext must be used within a DetailViewProvider"
    );
  return context;
};

function DetailViewProvider<E extends Entity>({
  children,
  entityType,
}: {
  children: React.ReactNode;
  entityType: EntityType;
}) {
  const { entityId } = useParams();
  const [entity, setEntity] = useState<E | undefined>(undefined);

  useEffect(() => {
    getOne<E>(entityType, true, Number(entityId)).then((cartData) => {
      setEntity(cartData);
    });
  }, [entityId, entityType]);

  const setEntityData = useCallback((entityData: E | undefined) => {
    setEntity(entityData);
  }, []);

  const value = useMemo<DetailViewContextInterface<E> | undefined>(
    () =>
      entity
        ? {
            entity,
            setEntityData,
          }
        : undefined,
    [entity, setEntityData]
  );

  if (!value)
    return <div>Cargando {ENTITY_TYPE_TO_FRONTEND_NAME[entityType]}...</div>;

  return (
    <DetailViewContext.Provider value={value}>
      {children}
    </DetailViewContext.Provider>
  );
}

export default DetailViewProvider;
