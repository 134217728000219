import { CSSProperties, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  justifyContent?: string;
  row?: boolean;
  gap?: number;
  sx?: CSSProperties
}

const Stack = ({ gap = 16, row, justifyContent, children, sx }: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: row ? "row" : "column",
        gap,
        justifyContent,
        ...sx
      }}
    >
      {children}
    </div>
  );
};

export default Stack;
