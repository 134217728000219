import { useCallback } from "react";
import { useStoreContext } from "../../../contexts/storeContext";
import {
  EntityType,
  NewCartKitEntry,
  NewCartProductEntry,
  CartKitEntry,
} from "../../../types";
import { CartEntry } from "../PaginatedTableRow";
import { PaginatedEntityType, postOne } from "../../djangoHelpers";
import { useDataStore } from "./../../../store/dataStore";

const createCartEntry = (
  entityType: PaginatedEntityType,
  entityId: number,
  activeCartId?: number
) => {
  const cartEntry = {
    cart: activeCartId || null,
    quantity: 1,
    // TODO: if admin then allow speciied price type
    // specifiedPriceType: null,
    // TODO: add this options shebang
    // quantity: options?.quantity ? options.quantity : 1,
    // specifiedPriceType: options?.specifiedPriceType
    //   ? options.specifiedPriceType
    //   : null,
  };

  if (entityType === EntityType.Kits) {
    (cartEntry as NewCartKitEntry).kit = entityId;
  }

  if (entityType === EntityType.Products) {
    (cartEntry as NewCartProductEntry).product = entityId;
  }

  return cartEntry as NewCartKitEntry | NewCartProductEntry;
};

const usePostEntryToCart = (
  entityType: PaginatedEntityType,
  cartEntry: CartEntry | undefined,
  entityId: number
) => {
  const { activeCart, reloadActiveCart, loadActiveCart } = useStoreContext();
  const dataStoreApi = useDataStore((state) => state.api);

  const postEntryToCart = useCallback(() => {
    if (cartEntry) {
      throw new Error("EXISTING KIT ENTRY");
    }

    const newCartKitEntry = createCartEntry(
      entityType,
      entityId,
      activeCart?.id
    );

    const cartEntryEntityType =
      entityType === EntityType.Kits
        ? EntityType.CartKitEntries
        : EntityType.CartProductEntries;

    postOne<NewCartKitEntry | NewCartProductEntry, CartKitEntry>(
      cartEntryEntityType,
      newCartKitEntry
    ).then((resp) => {
      if (!activeCart) {
        loadActiveCart(resp.cart);
        dataStoreApi.loadRecentCarts();
        return;
      }
      // TODO: is this needed?
      reloadActiveCart();
    });
  }, [
    cartEntry,
    entityType,
    entityId,
    activeCart,
    reloadActiveCart,
    loadActiveCart,
    dataStoreApi,
  ]);
  return postEntryToCart;
};

export default usePostEntryToCart;
