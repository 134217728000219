import { EntityType } from "./types";

export const PAGINATION_COUNT = 10;

export enum Page {
  Products = "productos",
  Brands = "marcas",
  Kits = "kits",
  Carts = "cotizaciones",
  Admin = "admin",
}

export const ENTITY_TYPE_TO_PAGE: Partial<Record<EntityType, Page>> = {
  [EntityType.Brands]: Page.Brands,
  [EntityType.Products]: Page.Products,
  [EntityType.ProductPrices]: Page.Products,
  [EntityType.Categories]: Page.Products,
  [EntityType.ProductCategories]: Page.Products,
  [EntityType.Carts]: Page.Carts,
  [EntityType.Kits]: Page.Kits,
};

export const CART_ENTRY_ENTITY_TYPES = [
  EntityType.CartProductEntries,
  EntityType.CartKitEntries,
];

export const ENTITY_TYPE_TO_FRONTEND_NAME: Partial<Record<EntityType, string>> =
  {
    [EntityType.Brands]: "Marca",
    [EntityType.Products]: "Producto",
    [EntityType.ProductPrices]: "Precio de Producto",
    [EntityType.Categories]: "Tipo",
    [EntityType.Kits]: "Kit",
    [EntityType.Carts]: "Cotización",
    [EntityType.ClientTypes]: "Tipo de Cliente",
    [EntityType.Users]: "Usuario",
  };

export enum PriceType {
  One = 1,
  Two = 2,
  Three = 3,
}

export enum CartStatus {
  OPEN = "OPEN",
  OPEN_PRIVATE = "OPEN_PRIVATE",
  PENDING = "PENDING",
  CLOSED = "CLOSED",
}

export const StatusFrontendNames: Record<CartStatus, string> = {
  [CartStatus.OPEN]: "Abierto",
  [CartStatus.OPEN_PRIVATE]: "Abierto (Priv.)",
  [CartStatus.PENDING]: "Pendiente",
  [CartStatus.CLOSED]: "Cerrado",
};

export const PRICE_TYPE_LIST = (
  Object.keys(PriceType) as Array<keyof typeof PriceType>
)
  .filter((key) => isNaN(Number(key)))
  .map((priceType) => PriceType[priceType]);


export enum Currency {
  MXN = "MXN",
  USD = "USD",
}

export enum CookieKey {
  AuthenticationToken = "jwt",
  ActiveCartId = "aci",
}
