import AdminProvider from "../../contexts/AdminContext";
import { useCurrentUserContext } from "../../contexts/currentUserContext";
import StoreProvider from "../../contexts/storeContext";
import MainRoutes from "../Main/MainRoutes";
import NotSignedInPage from "./NotSignedInPage";

const LoginPageRouter = () => {
  const { currentUser, isStaff } = useCurrentUserContext();

  if (currentUser === undefined) {
    return <NotSignedInPage />;
  }

  if (isStaff) {
    return (
      <StoreProvider>
        <AdminProvider>
          <MainRoutes />
        </AdminProvider>
      </StoreProvider>
    );
  }

  return (
    <StoreProvider>
      <MainRoutes />
    </StoreProvider>
  );
};

export default LoginPageRouter;
