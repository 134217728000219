import { Entity, Filter } from "./types";

export const getValueFromEntity = (entity: Entity, field: string) => {
  const splitField = field.split(".");
  // TODO: fix ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let parent = entity as any;
  for (let i = 0; i < splitField.length - 1; i++) {
    const field = splitField[i] as keyof Entity;
    parent = parent[field];
  }

  const key = splitField[splitField.length - 1];
  const value = parent[key];
  return value;
};

export const convertFilterListToFilterString = (filterList: Filter[]) => {
  return filterList
    .map((filter) => `${filter.filter}=${filter.value}`)
    .join("&");
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidPassword = (password: string): boolean => {
  const isSixOrMoreChars = password.length >= 6;
  const containsLetter = /[a-zA-Z]/.test(password);
  const containsNumber = /\d/.test(password);

  return isSixOrMoreChars && containsLetter && containsNumber;
};

export const formatDate = (dateString: string) => {
  const dateObject = new Date(dateString);


  // Format the date using Intl.DateTimeFormat
  /* eslint-disable spellcheck/spell-checker */
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(dateObject);
  /* eslint-enable spellcheck/spell-checker */
  
};
