import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { Cart, DetCart, EntityType } from "../../../types";
import { putOne } from "../../djangoHelpers";
import { useStoreContext } from "../../../contexts/storeContext";
import { useCallback, useState } from "react";
import { useDetailViewContext } from "../../../contexts/DetailViewContext";

enum CheckoutStatus {
  "Unprocessed" = "Unprocessed",
  "Processing" = "Processing",
  "Processed" = "Processed",
  "Error" = "Error",
}

const CartCheckoutConfirmationModal = ({
  open,
  hideModal,
  cart,
}: {
  open: boolean;
  hideModal: () => void;
  cart: DetCart;
}) => {
  const { setEntityData } = useDetailViewContext();
  const { setActiveCart } = useStoreContext();
  const [processingStatus, setProcessingStatus] = useState(
    CheckoutStatus.Unprocessed
  );

  const [cartComments, setCartComments] = useState(cart.comments);
  const checkout = useCallback(() => {
    setProcessingStatus(CheckoutStatus.Processing);
    putOne<Cart>(
      EntityType.Carts,
      cart.id,
      { comments: cartComments },
      "checkout-cart"
    )
      .then((res) => {
        if (res.success) {
          setProcessingStatus(CheckoutStatus.Processed);
          setEntityData(res.checked_out_cart);
          setActiveCart(undefined);
          hideModal();
        } else {
          setProcessingStatus(CheckoutStatus.Error);
        }
      })
      .catch(() => {
        setProcessingStatus(CheckoutStatus.Error);
      });
  }, [cart.id, cartComments, hideModal, setActiveCart, setEntityData]);

  return (
    <Modal
      show={open}
      onHide={hideModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Procesar Cotizacion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {processingStatus === CheckoutStatus.Unprocessed ? (
          <>
            <p>
              Desea procesar esta cotización? Su vendedor se pondra en contacto con
              used cuando existencia sea confirmada. La cotización no podra ser
              editado despues de este paso.
            </p>
            <Form.Group controlId="formGridAddress1">
              <Form.Label>Anotaciones</Form.Label>
              <Form.Control
                autoFocus={true}
                onChange={(e) => {
                  setCartComments(e.target.value);
                }}
                value={cart.comments || undefined}
                placeholder="Anotaciones..."
              />
            </Form.Group>
          </>
        ) : processingStatus === CheckoutStatus.Processing ? (
          <>
            <span className="visually-hidden">Processando...</span>
            <Spinner animation="border" role="status">
            </Spinner>
          </>
        ) : (
          <></>
        )}
        {/* TODO: rework text */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          Cerrar
        </Button>
        <Button
          variant="dark"
          onClick={checkout}
          disabled={processingStatus !== CheckoutStatus.Unprocessed}
        >
          Procesar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CartCheckoutConfirmationModal;
