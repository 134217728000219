import React, { useCallback, useMemo } from "react";
import { FormControl } from "react-bootstrap";
import { FaMinusCircle } from "react-icons/fa";
import { DetCartProductEntry, DetCart } from "../../../types";
import Input, { OnChangeFunction, DataType } from "../../Editors/Input";
import { useCurrentUserContext } from "../../../contexts/currentUserContext";
import ProductPriceRenderer from "../../Renderers/ProductPriceRenderer";
import { PriceType } from "../../../constants";

const CartDetailTableProduct = ({
  entry,
  removeProductEntryFromCart,
  changeProductPriceType,
  updateCartProductEntryQuantity,
  cart,
}: {
  entry: DetCartProductEntry;
  removeProductEntryFromCart: () => void;
  changeProductPriceType: (priceTypeId: number | null) => void;
  updateCartProductEntryQuantity: (quantity: number) => void;
  cart: DetCart;
}) => {
  const { isStaff: isAdmin } = useCurrentUserContext();
  const brandPriceType = useMemo(
    () =>
      cart.client.type?.brandPrices?.find(
        (x) => x.brand === entry.product.brand.id
      ),
    [cart.client.type?.brandPrices, entry.product.brand.id]
  );

  const specifiedPriceType = useMemo(
    () => entry.specifiedPriceType,
    [entry.specifiedPriceType]
  );

  const basePriceType = useMemo(
    () => cart.client.type?.basePriceType || PriceType.One,
    [cart.client.type?.basePriceType]
  );

  const value = useMemo(() => {
    return specifiedPriceType || brandPriceType || basePriceType;
  }, [basePriceType, brandPriceType, specifiedPriceType]);

  const onChange = useCallback<OnChangeFunction>(
    (newValue) => {
      changeProductPriceType(newValue);
    },
    [changeProductPriceType]
  );

  return (
    <tr className={entry.specifiedPriceType ? "table-warning" : ""}>
      <td className="table-cell-count">
        <FormControl
          // defaultValue="0"
          type="number"
          min="0"
          step="1"
          style={{ width: "100%" }}
          value={entry.quantity}
          className="input"
          aria-describedby="basic-addon1"
          onChange={(e) => {
            updateCartProductEntryQuantity(Number(e.target.value));
          }}
        />
      </td>
      <td>{entry.product.sku}</td>
      <td>{entry.product.name}</td>
      <td>{entry.product.brand.name}</td>
      <td>
        {!isAdmin ? (
          <ProductPriceRenderer productPrice={entry.product.productPrice} />
        ) : (
          <Input
            entity={entry.product}
            dataType={DataType.ProductPriceType}
            onChange={onChange}
            value={value}
          />
        )}
        {entry.specifiedPriceType && (
          <div
            onClick={() => {
              changeProductPriceType(null);
            }}
          >
            reset
          </div>
        )}
      </td>
      <td>{entry.peso_price}</td>
      <td
        onClick={() => {
          removeProductEntryFromCart();
        }}
        className="tableCell-remove"
      >
        <FaMinusCircle />
      </td>
    </tr>
  );
};

export default CartDetailTableProduct;
