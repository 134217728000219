import { Button } from "react-bootstrap";
import { useStoreContext } from "../../contexts/storeContext";
import { EntityType, CartKitEntry } from "../../types";
import { postOne } from "../djangoHelpers";
import { x } from "./KitDetail";
import { useCallback } from "react";

const AddKitToCartButton = ({
  kitId,
  selectableProductValues,
}: {
  kitId: number;
  selectableProductValues: x;
}) => {
  const { activeCart, loadActiveCart } = useStoreContext();

  const addKitToCart = useCallback(() => {
    const data = {
      kit: kitId,
      cart: activeCart?.id || null,
      // TODO: modify quantity
      quantity: 1,
      cartSelectableProductKitEntryEntries: Object.keys(
        selectableProductValues
      ).map((stringSelectableProductEntryId) => {
        const selectableProductEntryId = parseInt(
          stringSelectableProductEntryId
        );
        return {
          selectableProductKitEntry: selectableProductEntryId,
          product: selectableProductValues[selectableProductEntryId],
        };
      }),
    };
    postOne(EntityType.CartKitEntries, data)
      .then((resp) => {
        loadActiveCart((resp as CartKitEntry).cart);
      })
      .catch(() => {
        // TODO: clean this up
        // TODO: global error handling
        console.log("error outside");
      });
  }, [activeCart?.id, kitId, loadActiveCart, selectableProductValues]);

  return (
    <Button onClick={addKitToCart} variant="info">
      Agregar a la Cotización
    </Button>
  );
};

export default AddKitToCartButton;
