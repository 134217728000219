import { ENTITY_TYPE_TO_FRONTEND_NAME } from "../../constants";
import { DisplayedField, Entity } from "../../types";
import { PaginatedEntityType } from "../djangoHelpers";
import PaginatedTableRow from "./PaginatedTableRow";

const PaginatedTable = ({
  entityType,
  entities,
  displayedFields,
}: {
  entityType: PaginatedEntityType;
  entities: Entity[];
  displayedFields: DisplayedField[];
}) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col" style={{ width: 0 }}>
            +/-
          </th>
          {displayedFields.map((displayedField) => (
            <th key={displayedField.field} scope="col">
              {displayedField.label}
            </th>
          ))}
          <th scope="col" style={{ width: "50px" }}></th>
        </tr>
      </thead>
      <tbody>
        {!entities ? (
          <tr>
            <td className="text-center" colSpan={2}>
              Cargando {ENTITY_TYPE_TO_FRONTEND_NAME[entityType]}s...
            </td>
          </tr>
        ) : entities.length === 0 ? (
          <tr>
            <td className="text-center" colSpan={2}>
              No Hay {ENTITY_TYPE_TO_FRONTEND_NAME[entityType]}s...
            </td>
          </tr>
        ) : (
          entities.map((entity) => (
            <PaginatedTableRow
              key={entity.id}
              entity={entity}
              entityType={entityType}
              displayedFields={displayedFields}
            />
          ))
        )}
      </tbody>
    </table>
  );
};

export default PaginatedTable;
