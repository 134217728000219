import React, { useState } from "react";
import { Button, Row } from "react-bootstrap";
import { DetCart, EntityType } from "../../types";
import CartDetailTable from "./Detail/CartDetailTable";
import CartDetailTitle from "./CartDetailTitle";
import { useCurrentUserContext } from "../../contexts/currentUserContext";
import CartDetailComment from "./Detail/CartDetailComment";
import CartCheckoutConfirmationModal from "./Detail/CartCheckoutConfirmationModal";
import { useDetailViewContext } from "../../contexts/DetailViewContext";
import EditPropertyModal from "../Generic/EditPropertyModal";
import { CartStatus, ENTITY_TYPE_TO_FRONTEND_NAME } from "../../constants";
import { useUiStore } from "../../store/uiStore";
import { DataType } from "../Editors/Input";

const CartDetail = () => {
  const { entity: cart, setEntityData } = useDetailViewContext<DetCart>();
  const { isStaff: isAdmin } = useCurrentUserContext();
  // TODO: reimplement list view in cart
  // const [listView, setListView] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const uiStoreApi = useUiStore((state) => state.api);

  return (
    <div className="container">
      <div className="row">
        <div className="jumbotron col-12">
          <CartDetailTitle cart={cart} />
          <CartDetailComment cart={cart} />
          {isAdmin && (
            <>
              <p className="lead">
                Cliente: {cart.client.first_name} {cart.client.last_name}
              </p>
              <p className="lead">
                Tipo de Cliente:{" "}
                {cart.client.type ? cart.client.type.name : "Publico"}
              </p>
            </>
          )}
          <hr className="my-4" />

          <CartDetailTable
            // TODO: reimplement list view in cart
            // checkoutView={listView}
            checkoutView={false}
          />

          <hr className="my-4" />
          <div className="row">
            <div className="col-6">
              <table className="table black">
                <tbody>
                  <tr className="no-top">
                    <td>Subtotal</td>
                    <td className="left-text">{cart.total_prices.pre}</td>
                  </tr>
                  <tr>
                    <td>16% IVA</td>
                    <td className="left-text">{cart.total_prices.iva}</td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="">Total</h3>
                    </td>
                    <td>
                      <h3 className="left-text">{cart.total_prices.total}</h3>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-6 m-auto">
              <Row>
                <Button
                  size="lg"
                  className="m-auto"
                  variant="dark"
                  onClick={() => {
                    uiStoreApi.toggleShowCartDuplicateModal({
                      id: cart.id,
                      name: cart.name,
                      comments: cart.comments || undefined,
                    });
                  }}
                >
                  Duplicar
                </Button>
                {cart.status === CartStatus.OPEN && (
                  <>
                    <Button
                      size="lg"
                      className="m-auto"
                      variant="dark"
                      onClick={() => {
                        setIsEditing(true);
                      }}
                    >
                      Editar
                    </Button>
                    <Button
                      size="lg"
                      className="m-auto"
                      variant="info"
                      onClick={() => {
                        setConfirmModalOpen(true);
                      }}
                    >
                      Procesar
                    </Button>
                  </>
                )}
              </Row>
            </div>
          </div>
        </div>
        {/* TODO: re-enable when kits are implemented */}
        {/* <Button
          className="button"
          variant="outline-secondary"
          onClick={() => {
            setListView(!listView);
          }}
        >
          ListView
        </Button> */}
      </div>
      <EditPropertyModal<DetCart>
        isOpen={isEditing}
        hideModal={() => {
          setIsEditing(false);
        }}
        modalTitle={`Editar ${ENTITY_TYPE_TO_FRONTEND_NAME[EntityType.Carts]}`}
        propertiesToEdit={[
          { property: "name", label: "Nombre", dataType: DataType.String },
          {
            property: "comments",
            label: "Anotaciones",
            dataType: DataType.String,
          },
        ]}
        entityType={EntityType.Carts}
        entityId={cart.id}
        existingValue={{ name: cart.name, comments: cart.comments }}
        onComplete={(resData) => {
          setEntityData(resData);
        }}
      />
      <CartCheckoutConfirmationModal
        open={confirmModalOpen}
        hideModal={() => {
          setConfirmModalOpen(false);
        }}
        cart={cart}
      />
    </div>
  );
};

export default CartDetail;
