import React, { useCallback, useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { Cart, EntityType, PaginatedData } from "../../types";
import PaginationBar from "./../Ui/PaginationBar";
import { getList } from "../djangoHelpers";
import { CartStatus } from "../../constants";
import { useDataStore } from "../../store/dataStore";
import CartTableView from "./List/CartTable";
import { useUiStore } from "../../store/uiStore";

const CartList = ({ cartStatus }: { cartStatus: CartStatus }) => {
  const [cartsResponse, setCartsResponse] = useState<
    PaginatedData<Cart> | undefined
  >(undefined);
  const [filterString, setFilterString] = useState<string>("");

  const [currentPage, setCurrentPage] = useState<number>(1);

  const totalCartCount = useDataStore((state) => state.totalCartCount);

  const filterCarts = useCallback(async () => {
    const requestString = `name=${filterString}&page=${currentPage}&status=${cartStatus}`;
    const resp = await getList<Cart>(EntityType.Carts, false, false, requestString);
    setCartsResponse(resp);
  }, [cartStatus, currentPage, filterString]);

  const requestReload = useUiStore((state) => state.requestReload);
  const uiApi = useUiStore((state) => state.api);

  useEffect(() => {
    if (!cartsResponse || requestReload) {
      filterCarts();
      uiApi.setRequestReload(false);
    }
  }, [
    cartsResponse,
    currentPage,
    filterCarts,
    filterString,
    requestReload,
    totalCartCount,
    uiApi,
  ]);

  const carts = cartsResponse?.results;

  return (
    <div className="container">
      <div className="row">
        <InputGroup className="mb-3">
          {/* <InputGroup.Prepend>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  {this.state.filterType}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="all" onSelect={this.updateFilterType} >All</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="nombre" onSelect={this.updateFilterType}>Name</Dropdown.Item>
                  <Dropdown.Item eventKey="Brand" onSelect={this.updateFilterType}>Brand</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup.Prepend> */}
          <FormControl
            onChange={(e) => {
              setFilterString(e.target.value);
            }}
            placeholder="Buscar..."
            aria-label="Buscar..."
            aria-describedby="button-addon"
          />
        </InputGroup>
      </div>
      <div className="row">
        {!carts ? (
          <p>Cargando cotizaciones...</p>
        ) : carts.length === 0 ? (
          <p>No hay cotizaciones...</p>
        ) : (
          <CartTableView carts={carts} />
        )}
      </div>
      <div className="row">
        {cartsResponse && (
          <PaginationBar
            response={cartsResponse}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};

export default CartList;
