import { InputProps } from "../Input";
import { Entity } from "../../../types";
import SelectInput from "./SelectInput";
import { useMemo } from "react";
import { useAdminContext } from "../../../contexts/AdminContext";

interface Props<M extends Entity> extends InputProps<M> {
  staff?: boolean;
}

const UserInput = <M extends Entity>({ onChange, value, staff }: Props<M>) => {
  const { users } = useAdminContext();

  const options = useMemo(
    () =>
      users
        .filter((u) => (staff ? u.is_staff : !u.is_staff))
        .map((client) => ({
          value: client.id,
          label: `${client.first_name} ${client.last_name}`,
        })),
    [staff, users]
  );

  return <SelectInput options={options} onChange={onChange} value={value} />;
};

export default UserInput;
