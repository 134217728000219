import React, { useMemo } from "react";
import { Entity } from "../../../types";
import { InputProps } from "./../Input";
import SelectInput from "./SelectInput";
import { SelectInputOption } from "../types";
import { useCurrentUserContext } from "../../../contexts/currentUserContext";
import { PRICE_TYPE_LIST } from "../../../constants";

const PriceTypeInput = <M extends Entity>(props: InputProps<M>) => {
  const { isStaff: isAdmin } = useCurrentUserContext();

  if (!isAdmin) {
    throw new Error(
      "PriceTypeInput component should only render if user is admin"
    );
  }

  const priceTypeOptions = useMemo<SelectInputOption[]>(
    () =>
      PRICE_TYPE_LIST.map((priceType) => ({
        value: priceType,
        label: `Precio ${priceType}`,
      })),
    []
  );

  return <SelectInput {...props} options={priceTypeOptions} />;
};

export default PriceTypeInput;
