import { useCallback, useEffect, useState } from "react";
import { EntityType, Kit, NewKit } from "../../types";
import { DataType } from "../Editors/Input";
import EntityEditorLayout from "../Admin/EntityEditor/EntityEditorLayout";
import { Field } from "../Admin/EntityEditor/EntityEditorEntityFieldInput";
import { getList } from "../djangoHelpers";

const NEW_KIT_TEMPLATE: NewKit = {
  name: "",
  kitProducts: [],
  kitKits: [],
  kitSelectableProducts: [],
};

const KIT_ENTITY_FIELDS: Field<Kit, NewKit>[] = [
  {
    id: "name",
    label: "Nombre",
    dataType: DataType.String,
  },
  {
    id: "kitProducts",
    label: "Productos",
    dataType: DataType.KitProductEntry,
  },
  // {
  //   id: "kitKits",
  //   label: "Kits",
  //   dataType: DataType.KitKitEntry,
  // },
  // {
  //   id: "kitSelectableProducts",
  //   label: "Producto eleccionable",
  //   dataType: DataType.KitSelectableProductEntry,
  // },
];
const KitCreate = () => {
  // const kitProductsForm = useRef<HTMLSelectElement>(null);

  // const onClick = useCallback(() => {
  //   toggleShowModal();
  // }, [toggleShowModal]);

  const [kits, setKits] = useState<Array<Kit>>([]);

  const reloadKits = useCallback(() => {
    getList<Kit>(EntityType.Kits, false, false).then((data) => {
      setKits(data.results);
    });
  }, []);

  useEffect(() => {
    reloadKits();
  }, [reloadKits]);

  
  return (
    <>
      <EntityEditorLayout
        entityType={EntityType.Kits}
        reloadEntities={reloadKits}
        newEntityTemplate={NEW_KIT_TEMPLATE}
        entityFields={KIT_ENTITY_FIELDS}
        entityList={kits}
      />
    </>
    // <Button variant="info" onClick={onClick} block>
    //   Crear Kito
    // </Button>
    // <Modal
    //   show={showModal}
    //   size="lg"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    // >
    //   <Modal.Header closeButton>
    //     <Modal.Title>Kito Nuevo</Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     <Form>
    //       <Form.Group controlId="formGridAddress1">
    //         {/* TODO: frontend name */}
    //         <Form.Label>Nombre</Form.Label>
    //         <Form.Control
    //           onChange={(e) => {
    //             upateNewKit("name", e.target.value);
    //           }}
    //           placeholder="Nombre..."
    //         />
    //       </Form.Group>
    //     </Form>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button variant="secondary" onClick={onClick}>
    //       Cerrar
    //     </Button>
    //     <Button variant="dark" onClick={postNewKit}>
    //       Guardar Cambios
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
  );
};

export default KitCreate;
