import React, { useCallback } from "react";
import { useStoreContext } from "../../contexts/storeContext";
import { Cart, EntityType } from "../../types";
import { CartStatus, ENTITY_TYPE_TO_PAGE } from "../../constants";
import GenericCard from "../Generic/GenericCard/GenericCard";
import { formatDate } from "../../utils";
import CartStatusRenderer from "../Generic/CartStatusRenderer";

const CartCard = ({ cart, height }: { cart: Cart; height?: number }) => {
  const { activeCart, setActiveCart } = useStoreContext();
  const isActiveCart = activeCart && activeCart.id === cart.id;

  const handleClick = useCallback(() => {
    setActiveCart(cart.id);
  }, [cart, setActiveCart]);

  return (
    <GenericCard
      link={`/${ENTITY_TYPE_TO_PAGE[EntityType.Carts]}/${cart.id}`}
      title={cart.name}
      buttonDisabled={isActiveCart}
      buttonText={isActiveCart ? "Cotización en Uso" : "Usar Cotización"}
      buttonVariant={isActiveCart ? "primary" : "secondary"}
      buttonOnClick={cart.status === CartStatus.OPEN ? handleClick : undefined}
      height={height}
    >
      {/* TODO: add date total and product count to cart */}
      <p>{formatDate(cart.created)}</p>
      <div>
        <CartStatusRenderer status={cart.status} />
      </div>
      <p>
        {cart.flat_products.length} producto
        {cart.flat_products.length === 1 ? "" : "s"}
      </p>
      <p>{cart.total_prices.total}</p>
    </GenericCard>
  );
};

export default CartCard;
