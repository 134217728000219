import React, { useCallback, useEffect, useState } from "react";
import { DetKit, EntityType } from "../../types";
import KitDetailKitEntry from "./KitDetailKitEntry";
import { getOne } from "../djangoHelpers";
import AddKitToCartButton from "./AddKitToCartButton";
import { Row } from "react-bootstrap";
import KitSelectableProductEntry from "./KitSelectableProductEntry";
import { useParams } from "react-router-dom";

export type x = Record<number, number>;

const KitDetail = ({ passedKit }: { passedKit?: DetKit }) => {
  const { kitId } = useParams();
  const [kit, setKit] = useState<DetKit | undefined>(undefined);

  const [flatView] = useState(true);

  useEffect(() => {
    if (!kit && !passedKit) {
      (async () => {
        const kitData = await getOne<DetKit>(
          EntityType.Kits,
          true,
          Number(kitId)
        );

        if (kitData) {
          setKit(kitData);
        }
      })();
    }
  }, [kit, kitId, passedKit]);

  const [selectableProductValues, setSelectableProductValues] = useState<x>({});

  const setSelectableProductValuesHelper = useCallback(
    (selectableProductEntryId: number, productId: number) => {
      const y = {
        ...selectableProductValues,
        [selectableProductEntryId]: productId,
      };

      setSelectableProductValues(y);
    },
    [selectableProductValues]
  );

  if (kit === undefined) return <p>Cargando...</p>;

  return (
    <div className="container">
      <div className="row">
        <div className="jumbotron col-12">
          <div className="row">
            <div className="d-flex flex-row">
              <h1 className="display-3">{kit.name}</h1>
            </div>
          </div>
          <div className="row"></div>

          {flatView ? (
            <div>
              {kit.flat_products.selectable_product_entries.map(
                (kitSelectableProductEntryWrapper) => {
                  return (
                    <KitSelectableProductEntry
                      key={
                        kitSelectableProductEntryWrapper
                          .selectable_product_entry.id
                      }
                      quantity={kitSelectableProductEntryWrapper.quantity}
                      kitSelectableProductEntry={
                        kitSelectableProductEntryWrapper.selectable_product_entry
                      }
                      value={
                        selectableProductValues[
                          kitSelectableProductEntryWrapper
                            .selectable_product_entry.id
                        ]
                      }
                      setValue={setSelectableProductValuesHelper}
                    />
                  );
                }
              )}
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Cantidad</th>
                    <th scope="col">SKU</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Marca</th>
                    <th scope="col">Precio</th>
                  </tr>
                </thead>
                <tbody>
                  {kit.flat_products.products.map((productEntry) => (
                    <tr key={productEntry.product.id}>
                      <td>{productEntry.quantity}</td>
                      <td>{productEntry.product.sku}</td>
                      <td>{productEntry.product.name}</td>
                      <td>{productEntry.product.brand.name}</td>
                      <td>{productEntry.product.productPrice?.peso_price}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <KitDetailKitEntry
              kit={kit}
              selectableProductValues={selectableProductValues}
              setSelectableProductValues={setSelectableProductValuesHelper}
              root
            />
          )}
          <Row>
            <AddKitToCartButton
              kitId={kit.id}
              selectableProductValues={selectableProductValues}
            />
          </Row>
        </div>
      </div>
    </div>
  );
};

export default KitDetail;
