import React, { useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { ClientTypeBrandPrice, Entity } from "../../../../types";
import { InputProps } from "../../Input";
import BrandPriceTableEntry from "./BrandPriceTableEntry";
import NewBrandPriceTableEntry from "./NewBrandPriceTableEntry";

interface BrandPriceInuput<M extends Entity> extends InputProps<M> {
  value: ClientTypeBrandPrice[];
  entity: M;
}

const BrandPriceInput = <M extends Entity>({
  value,
  onChange,
  entity,
}: BrandPriceInuput<M>) => {
  const [showNewBrandPrice, setShowNewBrandPrice] = useState<boolean>(false);
  const clientTypeId = useMemo(() => {
    if (!entity) {
      throw new Error("Brand Price input needs an entity to be defined");
    }
    return entity.id;
  }, [entity]);

  return (
    <div>
      <Button
        onClick={() => {
          setShowNewBrandPrice(true);
        }}
        variant="info"
      >
        Especificar Precio para Marca
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Marca</th>
            <th>Tipo de Precio</th>
            <th style={{ width: "0px" }}>+/-</th>
          </tr>
        </thead>
        <tbody>
          {showNewBrandPrice && (
            <NewBrandPriceTableEntry clientTypeId={clientTypeId} />
          )}
          {value.length !== 0 &&
            value.map((brandPrice) => (
              <BrandPriceTableEntry
                key={brandPrice.id}
                brandPrice={brandPrice}
                onChange={onChange}
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default BrandPriceInput;
