import { FaPlusCircle } from "react-icons/fa";
import { CartEntry } from "./PaginatedTableRow";
import { Form } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { PaginatedEntityType, patchOne } from "../djangoHelpers";
import {
  CartProductEntry,
  Entity,
  EntityType,
  OnChangeEvent,
} from "../../types";

const EntityTypeToEntry: Partial<Record<PaginatedEntityType, EntityType>> = {
  [EntityType.Products]: EntityType.CartProductEntries,
};

const AddToCartButton = ({
  cartEntry,
  entityType,
  postEntryToCart,
  deleteEntryFromCart,
}: {
  cartEntry: CartEntry | undefined;
  entityType: PaginatedEntityType;
  postEntryToCart: () => void;
  deleteEntryFromCart: () => void;
}) => {
  const [quantity, setQuantity] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (cartEntry) {
      setQuantity(cartEntry.quantity);
    } else {
      setQuantity(undefined);
    }
  }, [cartEntry]);

  const onChange = useCallback(
    (event: OnChangeEvent) => {
      if (!cartEntry) {
        throw new Error("Cart Entry not defined");
      }
      // Allow only integer input
      const stringValue = event.target.value.replace(/\D/g, "");

      if (stringValue === "") {
        setQuantity(0);
        return;
      }

      let value = parseInt(event.target.value.replace(/\D/g, ""));
      if (isNaN(value)) {
        return;
      }

      value = Math.max(value, 0);
      setQuantity(value);

      const dataToUpdate = {
        quantity: value,
      } as Partial<Entity>;

      // TODO: HANDLE KIT CART ENTRY EDIT
      console.log(dataToUpdate);
      const realEntityType = EntityTypeToEntry[entityType];
      if (realEntityType) {
        patchOne(realEntityType, cartEntry.id, dataToUpdate).then(
          (res: CartProductEntry) => {
            setQuantity(res.quantity);
          }
        );
      }
    },
    [cartEntry, entityType]
  );

  const onBlur = useCallback(() => {
    if (quantity === 0) {
      if (quantity === 0) {
        deleteEntryFromCart();
        return;
      }
    }
  }, [deleteEntryFromCart, quantity]);

  return (
    <>
      {!cartEntry ? (
        <td className="tableCell-add" onClick={postEntryToCart}>
          <FaPlusCircle />
        </td>
      ) : (
        <td
          className="tableCell-remove"
          // onClick={deleteEntryFromCart}
        >
          <span>
            <Form>
              <Form.Group controlId="integerInput">
                <Form.Control
                  autoFocus={true}
                  style={{ padding: 0, width: "75px" }}
                  value={quantity || 0}
                  type="number"
                  min="0"
                  step="1"
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Form>
            {/* <FaTimesCircle /> */}
          </span>
        </td>
      )}
    </>
  );
};
export default AddToCartButton;
