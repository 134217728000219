import React from "react";
import RecentCarts from "./RecentCarts";
import TutorialRow from "./TutorialRow";
import { Page } from "../../constants";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import Stack from "../Ui/Stack";
import DashboardBrandsSection from "./DashboardBrandsSection";

const CONTAINER_STYLE = {
  marginBottom: "160px",
};

const Dashboard = () => {
  // this.postList()
  return (
    <div className="container" style={CONTAINER_STYLE}>
      <Stack row justifyContent="center">
        <Image src="/img/logo.svg" style={{ width: 300, margin: "20px" }} />
      </Stack>

      <TutorialRow
        stepNumber={1}
        title={"Crea y escoja una Cotización"}
        description={
          <span>
            En el cotizador puedes crear múltiples cotizaciones para separar y
            organizarlas fácilmente. Asegúrate de asignar nombres únicos a
            cada cotización para mantener un orden claro. Las cotizaciones pueden ser
            editadas antes de procesar y aquellas ya procesadas
            pueden ser duplicadas fácilmente.
            <br />
            Para ver todas tus cotizaciones procesadas, haz clic{" "}
            <a href={`/${Page.Carts}`}>aquí</a>.
          </span>
        }
      >
        <RecentCarts />
      </TutorialRow>
      <TutorialRow
        stepNumber={2}
        title={"Agrega productos a tu cotización activa"}
        description={
          <span>
            Una vez que tengas un cotización activa, puedes buscar productos por
            código, descripción o marca.
          </span>
        }
      >
        <DashboardBrandsSection />
      </TutorialRow>
      <TutorialRow
        stepNumber={3}
        title={"Procesa tu cotización"}
        description={
          <span>
            Cuando tu cotización esté lista para ser procesada, haz clic en el
            botón "Procesar" en la página de detalles de tu cotización. Una vez
            procesada, recibirás un correo electrónico con los
            detalles de tu pedido.
          </span>
        }
      >
        <div className="d-flex justify-content-center mt-4">
          <Link
            style={{ margin: "30px auto" }}
            to={`/${Page.Carts}`}
            className="btn btn-info"
            role="button"
          >
            Ver mis cotizaciones
          </Link>
        </div>
      </TutorialRow>
      <TutorialRow
        stepNumber={4}
        title={"Espera una respuesta de tu vendedor"}
        description={
          <span>
            Una vez que tu vendedor confirme los tiempos de entrega de tu
            cotización, se pondrá en contacto contigo para proceder con el pago.
            Toma en cuenta que los precios sugeridos en el cotizador son
            estimados y están sujetos a cambios sin previo aviso.
          </span>
        }
      />
    </div>
  );
};

export default Dashboard;
