import axios from "axios";
import { checkEntryEntities } from "../Helper";
import {
  Entity,
  EntityType,
  NewEntity,
  DetCart,
  PaginatedData,
} from "../types";
import Cookies from "js-cookie";
import { CookieKey } from "../constants";

export const SITE_URL = process.env.REACT_APP_API_URL;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: SITE_URL, // Replace with your API base URL
  withCredentials: true,
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response, // If the response is successful, just return it
  (error) => {
    // Handle the error
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { status } = error.response;

      if (status === 403) {
        // Handle 403 error - Redirect to login page
        window.location.href = "/";
      }
      // Add more error handling as needed
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error", error.message);
    }

    // Reject the promise to ensure any downstream code knows there was an error
    return Promise.reject(error);
  }
);

export type PaginatedEntityType =
  | EntityType.Products
  | EntityType.Kits
  | EntityType.Carts
  | EntityType.ProductPrices;

export type NonPaginatedEntityType = Exclude<EntityType, PaginatedEntityType>;

interface FunctionType {
  <M extends Entity>(
    entityType: PaginatedEntityType,
    details: boolean,
    noPagination: false | undefined,
    query?: string,
  ): Promise<PaginatedData<M>>;
  <M extends Entity>(
    entityType: EntityType,
    details: boolean,
    noPagination: true,
    query?: string,
  ): Promise<M[]>
  <M extends Entity>(
    entityType: EntityType,
    details: boolean,
    noPagination: boolean | undefined,
    query?: string,
  ): Promise<M[]>;
}

const getHeaders = () => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${Cookies.get(CookieKey.AuthenticationToken)}`,
});

export const searchOne = async <M extends Entity>(
  entityType: EntityType,
  details: boolean,
  query: string
) => {
  try {
    const resp = await axiosInstance.get<M>(
      `/${entityType}/?${details ? "details&" : ""}search=${query}`,
      { headers: getHeaders() }
    );
    return resp.data;
  } catch (error) {
    console.log(`ERROR! get search request for ${entityType}: ${query} failed`);
  }
};

export const getOne = async <M extends Entity>(
  entityType: EntityType,
  details: boolean,
  id: number,
  onFail?: () => void
) => {
  try {
    const resp = await axiosInstance.get<M>(
      `/${entityType}/${id}/${details ? "?details" : ""}`,
      { headers: getHeaders() }
    );
    return resp.data;
  } catch (err) {
    console.log(`ERROR! get request for ${entityType}: ${id} failed`);
    onFail && onFail();
  }
};

export const getList: FunctionType = async (entityType, details, noPagination, query) => {
  try {
    const resp = await axiosInstance.get(
      `/${entityType}/?${noPagination ? "noPagination&" : ""}${details ? "details&" : ""}${query || ""}`,
      { headers: getHeaders() }
    );
    return resp.data;
  } catch (err) {
    console.log(
      `ERROR! get request for  ${
        query ? "filtered " : ""
      } ${entityType}s failed`
    );
    throw err;
  }
};

export const postOne = async <NM extends NewEntity, M extends Entity>(
  entityType: EntityType,
  data: NM,
  onComplete?: (respData: M) => void
) => {
  try {
    const resp = await axiosInstance.post<M>(`/${entityType}/`, data, {
      headers: getHeaders(),
    });

    onComplete && onComplete(resp.data);

    return resp.data;
  } catch (err) {
    console.log(`ERROR! post request for ${entityType} failed`);
    throw err;
  }
};

export const patchOne = async (
  entityType: EntityType,
  id: number,
  data: Partial<Entity>
) => {
  try {
    const resp = await axiosInstance.patch(`/${entityType}/${id}/`, data, {
      headers: getHeaders(),
    });
    return resp.data;
  } catch (err) {
    console.log(`ERROR! patch request for ${entityType}: ${id} failed`);
    throw err;
  }
};

export const putOne = async <E extends Entity>(
  entityType: EntityType,
  id: number,
  data: Partial<E>,
  // TODO: ENUM?
  actionName: string
) => {
  try {
    const resp = await axiosInstance.put(
      `/${entityType}/${id}/${actionName ? `${actionName}/` : ""}`,
      data,
      {
        headers: getHeaders(),
      }
    );
    return resp.data;
  } catch (err) {
    console.log(`ERROR! patch request for ${entityType}: ${id} failed`);
    throw err;
  }
};

export const deleteOne = async (entityType: EntityType, id: number) => {
  try {
    const resp = await axiosInstance.delete(`/${entityType}/${id}`, {
      headers: getHeaders(),
    });
    return resp.data;
  } catch (err) {
    console.log(`ERROR! delete request for ${entityType}: ${id} failed`);
    throw err;
  }
};

export const updateCartEntryQuantity = async (
  entityType: EntityType,
  cartId: number,
  entryId: number,
  quantity: number
) => {
  checkEntryEntities(entityType);
  const newQuantity = { quantity: Math.max(quantity, 0) } as Partial<Entity>;
  await patchOne(entityType, entryId, newQuantity);
  const resp = await getOne<DetCart>(EntityType.Carts, true, cartId);
  return resp;
};

export const updateCartEntryPriceType = async (
  entityType: EntityType,
  cartId: number,
  entryId: number,
  priceTypeId: number | null
) => {
  checkEntryEntities(entityType);
  const newData = { specifiedPriceType: priceTypeId } as Partial<Entity>;
  await patchOne(entityType, entryId, newData);
  const resp = await getOne<DetCart>(EntityType.Carts, true, cartId);
  return resp;
};

export const removeEntryFromCart = async (
  entityType: EntityType.CartProductEntries | EntityType.CartKitEntries,
  cartId: number,
  entryId: number
) => {
  checkEntryEntities(entityType);
  await deleteOne(entityType, entryId);
  const resp = await getOne<DetCart>(EntityType.Carts, true, cartId);
  return resp;
};

export const uploadCsv = async (csvRows: Array<string>) => {
  try {
    const resp = await axiosInstance.put(
      `/products/csv-upload/`,
      {
        csvDataList: csvRows,
      },
      {
        headers: getHeaders(),
      }
    );
    return resp.data;
  } catch (err) {
    console.log(`ERROR! csv upload failed`);
    throw err;
  }
};

export const uploadKitCsv = async (csvRows: Array<string>) => {
  try {
    const resp = await axiosInstance.put(
      `/kits/csv-upload/`,
      {
        csvDataList: csvRows,
      },
      {
        headers: getHeaders(),
      }
    );
    return resp.data;
  } catch (err) {
    console.log(`ERROR! csv upload failed`);
    throw err;
  }
};

export const sessionCart = async (userId: number | undefined) => {
  try {
    const resp = await axiosInstance.post(
      `/api/session-cart/`,
      {
        client_user_id: userId,
      },
      {
        headers: getHeaders(),
      }
    );
    return resp.data;
  } catch (err) {
    console.log(`ERROR! session cart`);
    throw err;
  }
};
