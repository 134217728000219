import SelectableProductInput from "../Editors/Implementations/SelectableProductInput";
import { DetSelectableProductKitEntry } from "../../types";

const KitSelectableProductEntry = ({
  quantity,
  kitSelectableProductEntry,
  value,
  setValue,
}: {
  quantity: number;
  kitSelectableProductEntry: DetSelectableProductKitEntry;
  value: number;
  setValue: (selectableProductEntryId: number, productId: number) => void;
}) => {
  const selectedProduct = kitSelectableProductEntry.productOptions.find(
    (x) => x.id === value
  );

  return (
    <div>
      <h5>{quantity}x: {kitSelectableProductEntry.description}</h5>
      {selectedProduct ? (
        <div>{quantity}x: Sku: {selectedProduct.sku}</div>
      ) : (
        <div>No hay un producto seleccionado</div>
      )}

      <SelectableProductInput
        selectableProductKitEntry={kitSelectableProductEntry}
        onChange={(v) => {
          setValue(kitSelectableProductEntry.id, v);
        }}
        value={value || -1}
      />
    </div>
  );
};

export default KitSelectableProductEntry;
