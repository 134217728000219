import DetailViewProvider from "../../contexts/DetailViewContext";
import { EntityType } from "../../types";
import BrandDetail from "../Brands/BrandDetail";
import CartDetail from "../Carts/CartDetail";
import ProductDetail from "../Products/ProductDetail";

interface Props {
  entityType: EntityType;
}

const DetailContent = ({ entityType }: Props) => {
  switch (entityType) {
    case EntityType.Products:
      return <ProductDetail />;
    case EntityType.Carts:
      return <CartDetail />;
    case EntityType.Brands:
      return <BrandDetail />;
    default:
      throw new Error("No Detail content for entity type " + entityType);
  }
};

const DetailWrapper = ({ entityType }: Props) => {
  return (
    <DetailViewProvider entityType={entityType}>
      <DetailContent entityType={entityType} />
    </DetailViewProvider>
  );
};

export default DetailWrapper;
