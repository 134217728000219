import { Dropdown } from "react-bootstrap";
import { useDataStore } from "../../store/dataStore";
import { useMemo } from "react";
import { useStoreContext } from "../../contexts/storeContext";
import { CartStatus } from "../../constants";

const RecentCartsDropdownItems = () => {
  const recentCarts = useDataStore((state) => state.recentCarts);
  const { activeCart, setActiveCart } = useStoreContext();

  const last5Carts = useMemo(() => {
    if (!activeCart) {
      return recentCarts
        .filter((x) => x.status === CartStatus.OPEN)
        .splice(0, 5);
    }
    return recentCarts
      .filter((x) => x.status === CartStatus.OPEN && x.id !== activeCart.id)
      .splice(0, 5);
  }, [activeCart, recentCarts]);

  return (
    <>
      <Dropdown.ItemText>Cambiar Cotización</Dropdown.ItemText>
      {last5Carts.map((c) => (
        <Dropdown.Item
          key={c.id}
          onClick={() => {
            setActiveCart(c.id);
          }}
        >
          {c.name}
        </Dropdown.Item>
      ))}
    </>
  );
};

export default RecentCartsDropdownItems;
