import { useMemo } from "react";
import { ClientType, EntityType, NewClientType } from "../../../../types";
import { DataType } from "../../../Editors/Input";
import { Field } from "../EntityEditorEntityFieldInput";
import EntityEditorLayout from "../EntityEditorLayout";
import { useAdminContext } from "../../../../contexts/AdminContext";

type M = ClientType;
type NM = NewClientType;

const ClientTypeEditor = () => {
  const { clientTypes, reloadClientTypes } = useAdminContext();

  const newClientTemplate = useMemo<NM>(
    () => ({
      name: `type_${clientTypes.length}`,
      basePriceType: -1,
      brandPrices: [],
    }),
    [clientTypes.length]
  );

  const clientTypeEntityFields = useMemo<Field<M, NM>[]>(
    () => [
      {
        id: "name",
        label: "Nombre",
        dataType: DataType.String,
      },
      {
        id: "basePriceType",
        label: "Precio Base",
        dataType: DataType.PriceType,
      },
      {
        id: "brandPrices",
        label: "Precios por Marca",
        dataType: DataType.BrandPrices,
        existingOnly: true,
      },
    ],
    []
  );

  return (
    <EntityEditorLayout<M, NM>
      entityType={EntityType.ClientTypes}
      reloadEntities={reloadClientTypes}
      newEntityTemplate={newClientTemplate}
      entityFields={clientTypeEntityFields}
      entityList={clientTypes}
    />
  );
};

export default ClientTypeEditor;
