import React, { useCallback, useMemo } from "react";
import { KV, Entity, NewEntity } from "../../../types";
import Input, { DataType, OnChangeFunction } from "../../Editors/Input";

export interface Field<M extends Entity, NM extends NewEntity> {
  id: keyof M & keyof NM;
  label: string;
  dataType: DataType;
  existingOnly?: boolean;
  defaultValue?: string | number | boolean;
}

const EntityEditorEntityFieldInput = <M extends Entity, NM extends NewEntity>({
  field,
  currentEntity,
  newEntity,
  updateEntityFields,
}: {
  field: Field<M, NM>;
  currentEntity: M | undefined;
  newEntity: NM;
  updateEntityFields: KV<M | NM>;
}) => {
  const onChange: OnChangeFunction = useCallback(
    (newValue) => {
      updateEntityFields(field.id, newValue);
    },
    [field.id, updateEntityFields]
  );

  const value = useMemo(() => {
    if (currentEntity) {
      return currentEntity[field.id];
    }
    return newEntity[field.id];
  }, [currentEntity, field.id, newEntity]);

  if (!currentEntity && field.existingOnly) {
    return <></>;
  }

  return (
    <Input
      value={value}
      label={field.label}
      dataType={field.dataType}
      onChange={onChange}
      entity={currentEntity}
    />
  );
};

export default EntityEditorEntityFieldInput;
