import { Form } from "react-bootstrap";
import { Entity, OnChangeEvent } from "../../../types";
import { InputProps } from "./../Input";
import { KeyboardEvent, useCallback } from "react";

interface StringInputProps<M extends Entity> extends InputProps<M> {
  type?: string;
}

const StringInput = <M extends Entity>({
  value,
  label,
  onChange,
  onCommit,
  isInvalid,
  type = "text",
  onKeyDown
}: StringInputProps<M>) => {
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    onKeyDown && onKeyDown(e)
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  };

  const onChangeHelper = useCallback(
    (event: OnChangeEvent) => {
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange]
  );

  return (
    <Form.Control
      value={value}
      placeholder={label ? `${label}...` : undefined}
      onChange={onChangeHelper}
      onBlur={onCommit}
      onKeyDown={handleKeyPress}
      isInvalid={isInvalid}
      type={type}
      multiple={true}
    />
  );
};

export default StringInput;
