import React from "react";
import { Brand } from "../../types";
import ProductList from "../Products/ProductList";
import { useDetailViewContext } from "../../contexts/DetailViewContext";

const BrandDetail = () => {
  const { entity: brand } = useDetailViewContext<Brand>();

  return (
    <div style={{width:'100%'}}>
      <h1 className="display-3">{brand.name}</h1>
      <ProductList staticFilters={[{ filter: "inbrand", value: brand.id }]} />
    </div>
  );
};

export default BrandDetail;
