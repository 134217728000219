import { Button, Card } from "react-bootstrap";
import React, { CSSProperties, useCallback, useMemo } from "react";
import GenericCardTitle from "./GenericCardTitle";
import { useNavigate } from "react-router-dom";

const IMAGE_STYLE = { padding: 30, margin: "auto" };
// const IMAGE_STYLE = { height: "200px", width: "100%", display: "block" };
const GenericCard = ({
  children,
  link,
  img,
  title,
  variant,
  buttonDisabled,
  buttonText,
  buttonOnClick,
  cardOnClick,
  buttonVariant,
  height,
  onImgError,
  style,
}: React.PropsWithChildren<{
  link?: string;
  title?: string;
  variant?: string;
  img?: string;
  buttonDisabled?: boolean;
  buttonText?: string;
  cardOnClick?: () => void;
  buttonOnClick?: () => void;
  buttonVariant?: string;
  height?: number;
  onImgError?: () => void;
  style?: CSSProperties;
}>) => {
  const cardStyle = useMemo(
    () => ({
      flex: 1,
      height: height,
      ...(style || {}),
    }),
    [height, style]
  );
  const navigate = useNavigate();

  const linkOnCLick = !title && link;

  const onClick = useCallback(() => {
    cardOnClick && cardOnClick();
    if (linkOnCLick) {
      navigate(link);
    }
  }, [cardOnClick, link, linkOnCLick, navigate]);

  return (
    <div className="d-flex">
      <Card
        className={`${variant === "secondary" ? "" : "text-white"} bg-${
          variant || "dark"
        } ${linkOnCLick ? "pointer" : ""} mb-3 grow-1 `}
        onClick={onClick}
        style={cardStyle}
      >
        {title && (
          <GenericCardTitle link={link} variant={variant}>
            {title}
          </GenericCardTitle>
        )}
        {img && (
          <Card.Img
            variant="top"
            style={IMAGE_STYLE}
            src={img}
            onError={onImgError}
          />
        )}

        {children && <Card.Body>{children}</Card.Body>}
        {buttonOnClick && (
          <Button
            disabled={buttonDisabled}
            onClick={buttonOnClick}
            variant={buttonVariant}
          >
            {buttonText}
          </Button>
        )}
      </Card>
    </div>
  );
};

export default GenericCard;
