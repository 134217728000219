import { Button, Col, Container, Image, Row } from "react-bootstrap";
import LoginPage from "./LoginPage";
import RegisterPage from "./Registration/RegisterPage";
import { useState } from "react";
import Stack from "../Ui/Stack";

const NotSignedInPage = () => {
  const [showRegisterPage, setShowRegisterPage] = useState(false);

  return (
    <Container>
      <div className="jumbotron col-12">
        <Row>
          <Col />
          <Col xs={12} md={6}>
            <div
              style={{
                marginTop: 60,
              }}
            >
              <Stack sx={{ alignItems: "center" }}>
                <h1 style={{ margin: 0 }}>Cotizador</h1>
                <Image src="/img/logo.svg" style={{ width: "100%" }} />
                <Stack row justifyContent="center"></Stack>
              </Stack>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <Stack>
              {showRegisterPage ? (
                <RegisterPage setShowRegisterPage={setShowRegisterPage} />
              ) : (
                <LoginPage />
              )}
              <Button
                variant="link"
                onClick={() => {
                  setShowRegisterPage((showRegisterPage) => !showRegisterPage);
                }}
              >
                {showRegisterPage ? (
                  <>Ya tienes una cuenta? Ingresa aqui!</>
                ) : (
                  <>No tienes una cuenta? Registrate aqui!</>
                )}
              </Button>
            </Stack>
          </Col>
          <Col />
        </Row>
      </div>
    </Container>
  );
};

export default NotSignedInPage;
