import React, { useCallback } from "react";
import { Cart, EntityType } from "../../../types";
import CartStatusRenderer from "../../Generic/CartStatusRenderer";
import { Link, useNavigate } from "react-router-dom";
import { ENTITY_TYPE_TO_PAGE } from "../../../constants";
import {
  FaCopy,
  FaExternalLinkAlt,
  FaRegCheckCircle,
  FaRegCircle,
  FaTrash,
} from "react-icons/fa";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Stack from "../../Ui/Stack";
import { useUiStore } from "../../../store/uiStore";
import { useStoreContext } from "../../../contexts/storeContext";

interface Props {
  cart: Cart;
}

const CartTableRow = ({ cart }: Props) => {
  const navigate = useNavigate();
  const uiStoreApi = useUiStore((state) => state.api);

  const link = `/${ENTITY_TYPE_TO_PAGE[EntityType.Carts]}/${cart.id}`;

  const { activeCart, setActiveCart } = useStoreContext();
  const isActiveCart = activeCart && activeCart.id === cart.id;

  const handleClick = useCallback(() => {
    setActiveCart(cart.id);
  }, [cart, setActiveCart]);

  return (
    <tr>
      <td>
        <CartStatusRenderer status={cart.status} />
      </td>
      <td>
        <Link to={link}>{cart.name}</Link>
      </td>

      <td>{cart.created}</td>
      <td>{cart.product_quantity}</td>
      <td>{cart.total_prices.total}</td>
      <td>
        <Stack row>
          <OverlayTrigger
            overlay={!isActiveCart ? <Tooltip>Editar</Tooltip> : <></>}
          >
            <Button
              size="sm"
              variant={isActiveCart ? "success" : "secondary"}
              disabled={isActiveCart}
              onClick={handleClick}
            >
              {isActiveCart ? <FaRegCheckCircle /> : <FaRegCircle />}
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>Ver Detalles</Tooltip>}>
            <Button
              size="sm"
              variant="info"
              onClick={() => {
                navigate(link);
              }}
            >
              <FaExternalLinkAlt />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>Duplicar</Tooltip>}>
            <Button
              size="sm"
              variant="info"
              onClick={() =>
                uiStoreApi.toggleShowCartDuplicateModal({
                  id: cart.id,
                  name: cart.name,
                  comments: cart.comments || undefined,
                })
              }
            >
              <FaCopy />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>Borrar</Tooltip>}>
            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                uiStoreApi.setCartToDeleteData({
                  id: cart.id,
                  name: cart.name,
                });
              }}
            >
              <FaTrash />
            </Button>
          </OverlayTrigger>
        </Stack>
      </td>
    </tr>
  );
};

export default CartTableRow;
