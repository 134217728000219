import { useCallback, useRef, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getList } from "../../djangoHelpers";
import { Entity, EntityType } from "../../../types";

const Autocomplete = <E extends Entity>({
  value,
  entityType,
  onChange,
}: {
  value: any;
  entityType: EntityType;
  onChange: (selectedId: number) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<E[]>([]);

  const handleSearch = (query: string) => {
    setIsLoading(true);
    getList<E>(entityType, false, true, `all=${query}`).then((data) => {
      console.log(data);
      setOptions(data);
      setIsLoading(false);
    });
  };

  const onChangeHelper = useCallback(
    (options: E[]) => {
      if (options.length === 0) return;
      onChange(options[0].id);
    },
    [onChange]
  );
  const ref = useRef<HTMLInputElement | null>(null);

  console.log("vavavava", value);
  return (
    <AsyncTypeahead
      id={`Autocomplete=${entityType}`}
      inputRef={ref}
      onFocus={(event) => {
        console.log(event);
        if (event.currentTarget) {
          // TODO: fix this
          // event.nativeEvent.target?.select();
        }
        ref.current?.select();
      }}
      // Bypass client-side filtering by returning `true`. Results are already
      // filtered by the search endpoint, so no need to do it again.
      defaultSelected={value || []}
      filterBy={() => true}
      isLoading={isLoading}
      // @ts-expect-error - the typeahead component is not typed correctly
      onChange={onChangeHelper}
      labelKey="sku"
      minLength={3}
      onSearch={handleSearch}
      options={options}
      emptyLabel="No hay resultados"
      paginationText="Cargar mas resultados"
      placeholder="Buscar Productos..."
      // @ts-expect-error - the typeahead component is not typed correctly
      renderMenuItemChildren={(option: E) => (
        <>
          <span>
            {/* @ts-expect-error - TODO: get option labels safely */}
            {option.id}: {option.name}
          </span>
        </>
      )}
    />
  );
};

export default Autocomplete;
