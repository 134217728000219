import { DetCart } from "../../types";
import { Row } from "react-bootstrap";
import CartStatusRenderer from "../Generic/CartStatusRenderer";

const CartDetailTitle = ({ cart }: { cart: DetCart }) => {
  return (
    <Row direction="horizontal">
      <h1 className="display-3">{cart.name}</h1>

      <div className="mr-4 ml-auto mt-auto mb-auto">
        <CartStatusRenderer big status={cart.status} />
      </div>
    </Row>
  );
};

export default CartDetailTitle;
