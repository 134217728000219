import { Table } from "react-bootstrap";
import { Cart } from "../../../types";
import CartTableRow from "./CartTableRow";

const CartTable = ({ carts }: { carts: Array<Cart> }) => {
  return (
    <Table bordered hover size="sm">
      <thead>
        <tr>
          <th style={{ width: 0 }}></th>
          <th>Nombre</th>
          <th>Fecha</th>
          <th>#</th>
          <th>Total</th>
          <th style={{ width: 0 }}>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {carts.map((cart) => (
          <CartTableRow cart={cart} />
        ))}
      </tbody>
    </Table>
  );
};

export default CartTable;
