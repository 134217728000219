import { useCallback, useState } from "react";
import { Alert, Button, Card, CardBody } from "react-bootstrap";
import { useCurrentUserContext } from "../../../contexts/currentUserContext";
import Input, { DataType } from "../../Editors/Input";
import { RegistrationState } from "./types";
import Stack from "../../Ui/Stack";
import { isValidEmail, isValidPassword } from "../../../utils";

const RegistrationForm = ({
  setRegistrationState,
}: {
  setRegistrationState: React.Dispatch<React.SetStateAction<RegistrationState>>;
}) => {
  const { register } = useCurrentUserContext();
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>(
    undefined
  );

  const [showError, setShowError] = useState(false);

  const [nameIsInvalid, setNameIsInvalid] = useState(false);
  const [lastNameIsInvalid, setLastNameIsInvalid] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
  const [confirmPasswordIsInvalid, setConfirmPasswordIsInvalid] =
    useState(false);

  const onRegisterClick = useCallback(() => {
    let valid = true;
    if (!firstName || firstName.trim() === "") {
      valid = false;
      setNameIsInvalid(true);
    }
    if (!lastName || lastName.trim() === "") {
      valid = false;
      setLastNameIsInvalid(true);
    }
    if (!email || email.trim() === "" || !isValidEmail(email)) {
      valid = false;
      setEmailIsInvalid(true);
    }

    if (!password || password.trim() === "") {
      valid = false;
      setPasswordIsInvalid(true);
    }

    if (!confirmPassword || confirmPassword.trim() === "") {
      valid = false;
      setConfirmPasswordIsInvalid(true);
    }

    if (valid && firstName && lastName && email && password) {
      register(firstName, lastName, email, password, (success) => {
        if (success) {
          setShowError(false);
          setRegistrationState(RegistrationState.RegistrationSuccessful);
          return;
        }
        setShowError(true);
      });
    }
  }, [
    confirmPassword,
    email,
    firstName,
    lastName,
    password,
    register,
    setRegistrationState,
  ]);

  return (
    <Card>
      <CardBody>
        <Stack>
          <Input
            label="Nombre"
            dataType={DataType.String}
            onChange={(value) => {
              setFirstName(value);
              if (value && value.trim() !== "") {
                setNameIsInvalid(false);
              }
            }}
            value={firstName}
            isInvalid={nameIsInvalid}
          />
          <Input
            label="Apellido"
            dataType={DataType.String}
            onChange={(value) => {
              setLastName(value);
              if (value && value.trim() !== "") {
                setLastNameIsInvalid(false);
              }
            }}
            value={lastName}
            isInvalid={lastNameIsInvalid}
          />
          <Input
            label="Correo"
            dataType={DataType.Email}
            onChange={(value) => {
              setEmail(value);
              if (emailIsInvalid) {
                if (value && value.trim() !== "" && isValidEmail(value)) {
                  setEmailIsInvalid(false);
                }
              }
            }}
            value={email}
            isInvalid={emailIsInvalid}
          />

          <Input
            label="Contraseña"
            dataType={DataType.Password}
            value={password}
            onChange={(value) => {
              setPassword(value);
              if (passwordIsInvalid) {
                setPasswordIsInvalid(!isValidPassword(value));
              }
              if (confirmPassword && confirmPassword !== value) {

                setConfirmPasswordIsInvalid(true);
              } else {
                setConfirmPasswordIsInvalid(false);
              }
            }}
            onCommit={(e) => {
              setPasswordIsInvalid(!isValidPassword(e.target.value));
            }}
            isInvalid={passwordIsInvalid}
            invalidMessage="Su contraseña debe tener al menos 6 caracteres e incluir al menos una letra y un número."
          />

          <Input
            label="Confirmar Contraseña"
            dataType={DataType.Password}
            value={confirmPassword}
            onChange={(value) => {
              setConfirmPassword(value);
              setConfirmPasswordIsInvalid(password !== value);
            }}
            isInvalid={confirmPasswordIsInvalid}
            invalidMessage="Las contraseñas no coinciden."
          />
          <Button variant="dark" onClick={onRegisterClick}>
            Registrar
          </Button>

          {showError && (
            // TODO: get data from error message
            <Alert variant={"danger"}>
              Hubo un problema al registrar usuario. Por favor intente de nuevo.
            </Alert>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default RegistrationForm;
