import React, { useCallback } from "react";
import { Button } from "react-bootstrap";
import { ClientTypeBrandPrice, EntityType, KV } from "../../../../types";
import Input, { DataType, OnChangeFunction } from "../../Input";
import { patchOne, deleteOne } from "../../../djangoHelpers";
import { useAdminContext } from "../../../../contexts/AdminContext";

const BrandPriceTableEntry = ({
  brandPrice,
}: {
  brandPrice: ClientTypeBrandPrice;
  // TODO: onchange needed?
  onChange: OnChangeFunction;
}) => {
  const { reloadClientTypes } = useAdminContext();

  const updateBrandPrice: KV<ClientTypeBrandPrice> = useCallback(
    async (key, value) => {
      const tempBrandPrice = { ...brandPrice, [key]: value };
      await patchOne(
        EntityType.ClientTypeBrandPrice,
        brandPrice.id,
        tempBrandPrice
      );
      reloadClientTypes();
    },
    [brandPrice, reloadClientTypes]
  );

  const deleteBrandPrice = useCallback(async () => {
    await deleteOne(EntityType.ClientTypeBrandPrice, brandPrice.id);
    reloadClientTypes();
  }, [brandPrice.id, reloadClientTypes]);

  return (
    <tr key={brandPrice.id}>
      <td>
        <Input
          value={brandPrice.brand}
          dataType={DataType.Brand}
          onChange={(newValue) => {
            updateBrandPrice("brand", Number(newValue));
          }}
          entity={brandPrice}
        />
      </td>
      <td>
        <Input
          value={brandPrice.priceType}
          dataType={DataType.PriceType}
          onChange={(newValue) => {
            updateBrandPrice("priceType", Number(newValue));
          }}
          entity={brandPrice}
        />
      </td>
      <td>
        <Button
          variant="danger"
          onClick={() => {
            deleteBrandPrice();
          }}
        >
          -
        </Button>
      </td>
    </tr>
  );
};

export default BrandPriceTableEntry;
