import React, { useCallback, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import {
  ClientTypeBrandPrice,
  EntityType,
  KV,
  NewClientTypeBrandPrice,
} from "../../../../types";
import { postOne } from "../../../djangoHelpers";
import { PRICE_TYPE_LIST } from "../../../../constants";
import { useAdminContext } from "../../../../contexts/AdminContext";
import { useDataStore } from "../../../../store/dataStore";

const NewBrandPriceTableEntry = ({
  clientTypeId,
}: {
  clientTypeId: number;
}) => {
  const brands = useDataStore(state => state.brands);
  const { reloadClientTypes } = useAdminContext();

  // TODO: ts issue
  // @ts-expect-error what is going on here
  const newBrandPriceTemplate: NewClientTypeBrandPrice = useMemo(
    () => ({
      clientType: clientTypeId,
      brand: -1,
      priceType: -1,
    }),
    [clientTypeId]
  );

  const [newBrandPrice, setNewBrandPrice] = useState<NewClientTypeBrandPrice>(
    newBrandPriceTemplate
  );

  const updateNewBrandPrice: KV<NewClientTypeBrandPrice> = useCallback(
    (key, value) => {
      setNewBrandPrice({ ...newBrandPrice, [key]: value });
    },
    [newBrandPrice]
  );

  const actionDisabled = useMemo(
    // TODO: check if there is a way of getting rid of the ts error
    // @ts-expect-error what is going on here
    () => newBrandPrice.brand === -1 || newBrandPrice.priceType === -1,
    [newBrandPrice.brand, newBrandPrice.priceType]
  );

  const postNewBrandPrice = useCallback(async () => {
    await postOne<NewClientTypeBrandPrice, ClientTypeBrandPrice>(
      EntityType.ClientTypeBrandPrice,
      newBrandPrice
    );
    reloadClientTypes();
    setNewBrandPrice(newBrandPriceTemplate);
  }, [newBrandPrice, newBrandPriceTemplate, reloadClientTypes]);

  return (
    <tr key="new">
      <td>
        <Form.Control
          as="select"
          value={newBrandPrice.brand}
          onChange={(e) => {
            updateNewBrandPrice("brand", Number(e.target.value));
          }}
        >
          <option value={-1} disabled>
            Selecionar Marca
          </option>
          {brands.length === 0 ? (
            <option>Cargando Marcas...</option>
          ) : (
            brands.map((brand) => (
              <option key={brand.id} value={brand.id}>
                {brand.name}
              </option>
            ))
          )}
        </Form.Control>
      </td>
      <td>
        <Form.Control
          as="select"
          value={newBrandPrice.priceType}
          onChange={(e) => {
            updateNewBrandPrice("priceType", Number(e.target.value));
          }}
        >
          <option value={-1} key={-1}>
            Selecionar Precio
          </option>
          {PRICE_TYPE_LIST.map((price) => (
            <option key={price} value={price}>
              Precio {price}
            </option>
          ))}
        </Form.Control>
      </td>
      <td>
        <Button
          disabled={actionDisabled}
          variant="success"
          onClick={() => {
            postNewBrandPrice();
          }}
        >
          +
        </Button>
      </td>
    </tr>
  );
};

export default NewBrandPriceTableEntry;
