import { Alert, Button, Card, CardBody } from "react-bootstrap";
import Stack from "../../Ui/Stack";

const SuccessfulRegistration = ({
  setShowRegisterPage,
}: {
  setShowRegisterPage: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Card>
      <CardBody>
        <Stack gap={0}>
          <Alert variant="success">
            <Alert.Heading>Bienvenido!</Alert.Heading>
            Tu cuenta fue creada!
          </Alert>
          <Button
            onClick={() => {
              setShowRegisterPage(false);
            }}
          >
            Ingresar
          </Button>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default SuccessfulRegistration;
