import React from "react";
import BrandsGrid from "./BrandsGrid";
import { Container } from "react-bootstrap";

const BrandList = () => {
  return (
    <Container>
      <BrandsGrid />
    </Container>
  );
};

export default BrandList;
