import { PropsWithChildren } from "react";
import { CardHeader } from "react-bootstrap";
import { Link } from "react-router-dom";

interface GenericCardTitleContentProps extends PropsWithChildren {
  variant?: string;
}
interface Props extends GenericCardTitleContentProps {
  link?: string;
}

const GenericCardTitleContent = ({ children, variant }: Props) => {
  return (
    <div className="card-body">
      <h5
        className={`card-title ${
          variant === "secondary" ? "" : "text-white"
        } mb-0`}
      >
        {children}
      </h5>
    </div>
  );
};

const GenericCardTitle = ({ link, children, variant }: Props) => {
  if (link) {
    return (
      <CardHeader>
        <Link to={`${link}`} className="">
          <GenericCardTitleContent variant={variant}>
            {children}
          </GenericCardTitleContent>
        </Link>
      </CardHeader>
    );
  }
  return (
    <GenericCardTitleContent variant={variant}>
      {children}
    </GenericCardTitleContent>
  );
};

export default GenericCardTitle;
