import SelectInput from "./SelectInput";
import { InputProps } from "./../Input";
import { useMemo } from "react";
import { Entity } from "../../../types";
import { SelectInputOption } from "../types";
import { useDataStore } from "../../../store/dataStore";

const BrandInput = <M extends Entity>(props: InputProps<M>) => {
  const { brands } = useDataStore();

  const brandOptions = useMemo<SelectInputOption[]>(
    () =>
      brands.map((brand) => ({
        value: brand.id,
        label: brand.id + brand.name,
      })),
    [brands]
  );

  return <SelectInput {...props} options={brandOptions} />;
};

export default BrandInput;
