import React, { useCallback, useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Autocomplete from "./Autocomplete";
import { DetKitProductEntry, Entity, EntityType } from "../../../types";
import { InputProps } from "../Input";

interface ProductEntry {
  product_id: number;
  quantity: number;
}

interface Props<M extends Entity> extends InputProps<M> {}

const KitProductEntryInput = <M extends Entity>({
  value: existingKitProductEntries,
}: Props<M>) => {
  const [skuLookup, setSkuLookup] = useState<Record<number, string>>({});
  const [productEntries, setProductEntries] = useState<Array<ProductEntry>>([
    ...existingKitProductEntries.map((entry: DetKitProductEntry) => ({
      product_id: entry.childProduct.id,
      quantity: entry.quantity,
    })),
    { product_id: 0, quantity: 0 },
  ]);

  useEffect(() => {
    const skuLookup: Record<number, string> = {};
    existingKitProductEntries.forEach((entry: DetKitProductEntry) => {
      skuLookup[entry.childProduct.id] = entry.childProduct.sku;
    });
    setSkuLookup(skuLookup);
  }, [existingKitProductEntries]);

  const handleAddEntry = useCallback(() => {
    setTimeout(() => {
      console.log("adding");
      setProductEntries((e) => {
        console.log("setting to ", [...e, { product_id: 0, quantity: 0 }]);

        return [...e, { product_id: 0, quantity: 0 }];
      });
    }, 0);
  }, []);

  const handleProductChange = useCallback(
    (index: number, productId: number) => {
      setProductEntries((productEntries) => {
        const updatedEntries = [...productEntries];
        const entryToUpdate = updatedEntries[index];
        entryToUpdate.product_id = productId;

        if (
          entryToUpdate.quantity !== 0 &&
          index === updatedEntries.length - 1
        ) {
          handleAddEntry();
        }
        return updatedEntries;
      });
    },
    [handleAddEntry]
  );

  const handleQuantityChange = useCallback(
    (index: number, quantity: number) => {
      setProductEntries((productEntries) => {
        const updatedEntries = [...productEntries];
        const entryToUpdate = updatedEntries[index];
        entryToUpdate.quantity = quantity;
        console.log(entryToUpdate.product_id);
        if (
          entryToUpdate.product_id !== 0 &&
          index === updatedEntries.length - 1
        ) {
          handleAddEntry();
        }
        return updatedEntries;
      });
    },
    [handleAddEntry]
  );

  console.log("productEntries", productEntries);
  return (
    <div>
      {productEntries.map((entry, index) => (
        <Row key={index}>
          <Col style={{width:'50px'}}>
            <Form.Control
              type="number"
              value={entry.quantity}
              onChange={(e) =>
                handleQuantityChange(index, parseInt(e.target.value))
              }
            />
          </Col>
          <Col style={{width: '100%'}}>
            <Autocomplete
              entityType={EntityType.Products}
              value={[
                // TODO: SPELL
                {
                  id: entry.product_id,
                  sku: skuLookup[entry.product_id] || "not assigned",
                },
              ]}
              onChange={(id) => {
                console.log(id);
                handleProductChange(index, id);
              }}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default KitProductEntryInput;
