import { Button } from "react-bootstrap";
import { useUiStore } from "../../store/uiStore";
import { ENTITY_TYPE_TO_FRONTEND_NAME } from "../../constants";
import { EntityType } from "../../types";

const CartCreateButton = () => {
  const uiStoreApi = useUiStore((state) => state.api);

  return (
    <Button variant="info" onClick={uiStoreApi.toggleShowCartCreateModal}>
      Crear {ENTITY_TYPE_TO_FRONTEND_NAME[EntityType.Carts]}
    </Button>
  );
};

export default CartCreateButton;
