import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Cart, EntityType, KV, NewCart } from "../../types";
import { useUiStore } from "../../store/uiStore";
import { putOne } from "../djangoHelpers";
import { useCurrentUserContext } from "../../contexts/currentUserContext";
import UserInput from "../Editors/Implementations/UserInput";
import { useDataStore } from "../../store/dataStore";
import { useStoreContext } from "../../contexts/storeContext";
import { ENTITY_TYPE_TO_FRONTEND_NAME } from "../../constants";

const CartDuplicateModal = () => {
  const { isStaff: isAdmin } = useCurrentUserContext();

  const showCartDuplicateModal = useUiStore(
    (state) => state.showCartDuplicateModal
  );
  const cartToDuplicateData = useUiStore((state) => state.cartToDuplicateData);

  const [newCart, setNewCart] = useState<{
    id: number | undefined;
    name: string | undefined;
    comments: string | undefined;
    client: number | undefined;
  }>({
    id: cartToDuplicateData?.id || undefined,
    name: `${cartToDuplicateData?.name}-copia` || "",
    comments: cartToDuplicateData?.comments || "",
    client: undefined,
  });

  useEffect(() => {
    setNewCart({
      id: cartToDuplicateData?.id,
      name: cartToDuplicateData?.name
        ? `${cartToDuplicateData?.name}-copia`
        : undefined,
      comments: cartToDuplicateData?.comments,
      client: newCart.client,
    });
  }, [
    cartToDuplicateData?.comments,
    cartToDuplicateData?.id,
    cartToDuplicateData?.name,
    newCart.client,
  ]);

  const dataStoreApi = useDataStore((state) => state.api);
  const uiStoreApi = useUiStore((state) => state.api);
  const { setActiveCart } = useStoreContext();

  // updates state.newCartTemplate every time an input field from the create new product modal is changed
  const updateNewCartField: KV<NewCart> = useCallback(
    (type, value) => {
      setNewCart({ ...newCart, [type]: value });
    },
    [newCart]
  );

  const actionDisabled = useMemo(() => newCart.name === "", [newCart.name]);

  const saveNewCart = useCallback(() => {
    if (cartToDuplicateData?.id === undefined) {
      throw new Error("No cart ID to duplicate");
    }
    const cartData = { ...newCart };
    if (newCart.comments === "") {
      cartData.comments = undefined;
    }
    // @ts-expect-error Type mismatch mess
    putOne<Cart>(EntityType.Carts, cartData.id, cartData, "duplicate")
      .then((d) => {
        setActiveCart(d.id);
        dataStoreApi.loadRecentCarts();
        uiStoreApi.setRequestReload(true)
      })
      .catch((e) => {
      });

    uiStoreApi.toggleShowCartDuplicateModal();
  }, [
    cartToDuplicateData?.id,
    dataStoreApi,
    newCart,
    setActiveCart,
    uiStoreApi,
  ]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.code === "Enter") {
        saveNewCart();
      }
    },
    [saveNewCart]
  );

  return (
    <Modal
      show={showCartDuplicateModal}
      onHide={uiStoreApi.toggleShowCartDuplicateModal}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Duplicar {ENTITY_TYPE_TO_FRONTEND_NAME[EntityType.Carts]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formGridAddress1">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            defaultValue={newCart.name}
            autoFocus={true}
            onKeyDown={onKeyDown}
            onChange={(e) => {
              updateNewCartField("name", e.target.value);
            }}
            placeholder="Nombre..."
          />
          <Form.Label>Anotaciones</Form.Label>
          <Form.Control
            defaultValue={newCart.comments}
            onKeyDown={onKeyDown}
            onChange={(e) => {
              updateNewCartField("comments", e.target.value);
            }}
            placeholder="Anotaciones..."
          />
        </Form.Group>
        {isAdmin && (
          <>
            <Form.Label>Cliente</Form.Label>
            <UserInput
              onChange={(value) => updateNewCartField("client", Number(value))}
              value={undefined}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          type="submit"
          onClick={() => uiStoreApi.toggleShowCartDuplicateModal()}
        >
          Cerrar
        </Button>
        <Button variant="dark" onClick={saveNewCart} disabled={actionDisabled}>
          Duplicar {ENTITY_TYPE_TO_FRONTEND_NAME[EntityType.Carts]}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CartDuplicateModal;
