import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Page } from "../../constants";
import CartCreateModal from "../Carts/CartCreateModal";
import Dashboard from "../Dashboard/Dashboard";
// import KitMain from "./../Kits/KitMain";
import NavBar from "../NavBar/NavBar";
import { useDataStore } from "../../store/dataStore";
import { useCurrentUserContext } from "../../contexts/currentUserContext";
import ProductList from "../Products/ProductList";
import BrandList from "../Brands/BrandList";
import MainPageTemplate from "./MainPageTemplate";
import CartLists from "../Carts/CartLists";
import ClientTypeEditor from "../Admin/EntityEditor/Implementations/ClientTypesEditor";
import ClientsEditor from "../Admin/EntityEditor/Implementations/ClientsEditor";
import CsvUpload from "../Admin/csvUpload";
import CartCreateButton from "../Carts/CartCreateButton";
import PageNotFound from "./PageNotFound";
import DetailWrapper from "./DetailWrapper";
import { EntityType } from "../../types";
import CartDuplicateModal from "../Carts/CartDuplicateModal";
import CartDeletionConfirmModal from "../Carts/CartDeletionConfirmModal";
import KitCreate from "../Kits/KitCreate";
import KitDetail from "../Kits/KitDetail";
import KitList from "../Kits/KitList";

export enum AdminRoutes {
  ClientTypes = "client_types",
  Clients = "clients",
  KitCreate = "kit_create",
  CsvUpload = "csv_upload",
}

const ADMIN_PAGES = [
  { path: AdminRoutes.ClientTypes, component: ClientTypeEditor },
  { path: AdminRoutes.Clients, component: ClientsEditor },
  { path: AdminRoutes.KitCreate, component: KitCreate },
  { path: AdminRoutes.CsvUpload, component: CsvUpload },
];

const MainRoutes = () => {
  const dataStoreApi = useDataStore((state) => state.api);
  const { isStaff } = useCurrentUserContext();

  useEffect(() => {
    dataStoreApi.loadBrands();
    dataStoreApi.loadRecentCarts();
  }, [dataStoreApi]);

  return (
    <div>
      <NavBar />

      <Routes>
        <Route path="/" element={<Dashboard />} />

        <Route path={`/${Page.Products}`} element={<MainPageTemplate />}>
          <Route index element={<ProductList />} />
          <Route
            path={`:entityId`}
            element={<DetailWrapper entityType={EntityType.Products} />}
          />
        </Route>

        <Route path={`/${Page.Brands}`} element={<MainPageTemplate />}>
          <Route index element={<BrandList />} />
          <Route
            path={`:entityId`}
            element={<DetailWrapper entityType={EntityType.Brands} />}
          />
        </Route>

        <Route path={`/${Page.Kits}`} element={<MainPageTemplate />}>
          <Route index element={<KitList />} />
          <Route path=":kitId" element={<KitDetail />} />
        </Route>

        <Route
          path={`/${Page.Carts}`}
          element={<MainPageTemplate button={<CartCreateButton />} />}
        >
          <Route index element={<CartLists />} />
          <Route
            path={`:entityId`}
            element={<DetailWrapper entityType={EntityType.Carts} />}
          />
        </Route>

        {isStaff && (
          <Route path={`/${Page.Admin}`} element={<MainPageTemplate />}>
            {ADMIN_PAGES.map((adminPage) => (
              <Route
                key={adminPage.path}
                path={adminPage.path}
                element={<adminPage.component />}
              />
            ))}
            <Route
              path="*"
              element={
                <MainPageTemplate>
                  <PageNotFound />
                </MainPageTemplate>
              }
            />
          </Route>
        )}

        <Route
          path="*"
          element={
            <MainPageTemplate>
              <PageNotFound />
            </MainPageTemplate>
          }
        />
      </Routes>
      <CartCreateModal />
      <CartDuplicateModal />
      <CartDeletionConfirmModal />
    </div>
  );
};
export default MainRoutes;
