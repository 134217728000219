import CurrentUserProvider from "./contexts/currentUserContext";
import LoginPageRouter from "./components/LoginPage/LoginPageRouter";

const App = () => {
  return (
    <CurrentUserProvider>
      <LoginPageRouter />
    </CurrentUserProvider>
  );
};

export default App;
