import { FaPlus } from "react-icons/fa";
import { useUiStore } from "./../../../store/uiStore";
import { Button } from "react-bootstrap";

const NewCartCard = () => {
  const uiStoreApi = useUiStore((state) => state.api);

  return (
    <Button
      onClick={uiStoreApi.toggleShowCartCreateModal}
      className="cursor-clicker w-100"
    >
      <div style={{ height: 320 }} className="d-flex flex-column justify-content-center ">
        <FaPlus size={100} className="mx-auto" />
        <div className="text-center">Crear Cotización</div>
      </div>
    </Button>
  );
};

export default NewCartCard;
