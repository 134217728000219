import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { SITE_URL } from "../components/djangoHelpers";
import { CookieKey } from "../constants";

interface User {
  id: number;
  first_name: string;
  last_name: string;
  is_staff: boolean;
}

interface CurrentContextInterface {
  jwt?: string;
  register: (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    onComplete: (success: boolean) => void
  ) => void;
  login: (
    email: string,
    password: string,
    callback?: (success: boolean) => void
  ) => void;
  logout: () => void;
  currentUser: undefined | User;
  selectedClientId: undefined | number;
  setSelectedClientId: (clientId: number) => void;

  isStaff: boolean;
}

const CurrentUserContext = React.createContext<CurrentContextInterface | null>(
  null
);

const cookieJwt = Cookies.get(CookieKey.AuthenticationToken);
function CurrentUserProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [selectedClientId, setSelectedClientId] = useState<number | undefined>(
    undefined
  );
  const [jwt, setJwt] = useState(cookieJwt);

  const login = useCallback(
    (
      email: string,
      password: string,
      callback?: (success: boolean) => void
    ) => {
      axios
        .post(`${SITE_URL}/api/login/`, {
          email: email,
          password: password,
        })
        .then((resp) => {
          callback && callback(true);
          setJwt(resp.data.jwt);
          Cookies.set(CookieKey.AuthenticationToken, resp.data.jwt, {
            expires: 7,
          });
        })
        .catch((err) => {
          callback && callback(false);
          console.log(`ERROR! post request for login failed`, err);
        });
    },
    []
  );

  const register = useCallback(
    (
      firstName: string,
      lastName: string,
      email: string,
      password: string,
      onComplete: (success: boolean) => void
    ) => {
      try {
        axios
          .post(`${SITE_URL}/api/register/`, {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
          })
          .then((resp) => {
            onComplete(true);
            console.log("regitser worked", resp);
          });
      } catch (err) {
        onComplete(false);
        console.log(`ERROR! post request for register failed`);
        throw err;
      }
    },
    []
  );

  const logout = useCallback(() => {
    console.log("logout headers", {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get(CookieKey.AuthenticationToken)}`,
    });
    axios
      .post(
        `${SITE_URL}/api/logout/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get(
              CookieKey.AuthenticationToken
            )}`,
          },
        }
      )
      .then(() => {
        Cookies.remove(CookieKey.AuthenticationToken);
        setJwt(undefined);
        setCurrentUser(undefined);

        console.log("logout worked?");
      })
      .catch(() => {});
  }, []);

  const setSelectedClientIdHelper = (clientId: number) => {
    setSelectedClientId(clientId);
  };

  useEffect(() => {
    if (jwt) {
      axios
        .get(`${SITE_URL}/api/me/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((resp) => {
          setCurrentUser(resp.data);
        })
        .catch(() => {
          setJwt(undefined);
        });
    }
  }, [jwt]);

  const value = useMemo(
    () => ({
      jwt,
      register,
      login,
      logout,
      currentUser,
      isStaff: currentUser ? currentUser.is_staff : false,
      selectedClientId: selectedClientId,
      setSelectedClientId: setSelectedClientIdHelper,
    }),
    [currentUser, jwt, login, logout, register, selectedClientId]
  );

  return (
    <>
      <CurrentUserContext.Provider value={value}>
        {children}
      </CurrentUserContext.Provider>
    </>
  );
}

export const useCurrentUserContext = () => {
  return useContext(CurrentUserContext) as CurrentContextInterface;
};

export default CurrentUserProvider;
