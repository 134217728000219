import { useMemo } from "react";
import { parsePrice } from "../../../Helper";
import { InputProps } from "../Input";
import { DetProduct } from "./../../../types";
import SelectInput from "./SelectInput";
import { SelectInputOption } from "../types";
import { useCurrentUserContext } from "../../../contexts/currentUserContext";

interface ProductPriceTypeInputProps extends InputProps<DetProduct> {
  entity: DetProduct;
}

const ProductPriceTypeInput = (props: ProductPriceTypeInputProps) => {
  const { entity: product } = props;
  const { isStaff: isAdmin } = useCurrentUserContext();

  if (!isAdmin) {
    throw new Error("This should only render for admin users");
  }

  
  if (!product.productPrices) {
    throw new Error("Product Prices must be defined");
  }
  
  const options = useMemo<SelectInputOption[]>(() => {
    return product.productPrices!.map((productPrice) => ({
      value: productPrice.type,
      label: `Precio ${productPrice.type}: ${parsePrice(productPrice, 1)}`,
    }));
  }, [product.productPrices]);
  
  // TODO: this caused infinite loop. find out what happened 
  // const productPrice = useGetProductPrice(product);
  // useEffect(() => {
  //   if (productPrice) {
  //     console.log('from here')
  //     onChange(productPrice.type);
  //   }
  // });

  return <SelectInput {...props} options={options} />;
};

export default ProductPriceTypeInput;
