import { Alert, Button, Card, CardBody, Form } from "react-bootstrap";
import Input, { DataType } from "../Editors/Input";
import { useCallback, useState } from "react";
import { useCurrentUserContext } from "../../contexts/currentUserContext";
import Stack from "../Ui/Stack";

const LoginPage = () => {
  const { login } = useCurrentUserContext();
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);

  const [errorInLogin, setErrorInLogin] = useState(false);

  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);

  const loginAction = useCallback(() => {
    let valid = true;
    if (!email) {
      valid = false;
      setEmailInvalid(true);
    }

    if (!password) {
      valid = false;
      setPasswordInvalid(true);
    }

    if (valid && email && password) {
      setEmailInvalid(false);
      setPasswordInvalid(false);
      login(email, password, (success) => {
        if (success) {
          setErrorInLogin(false);
        } else {
          setErrorInLogin(true);
        }
      });
    }
  }, [email, login, password]);

  return (
    <Card>
      <CardBody>
        <Form onSubmit={loginAction}>
          <Stack>
            <Input
              dataType={DataType.Email}
              label="Correo"
              onChange={(value) => {
                if (value && value.trim() !== "") {
                  setEmailInvalid(false);
                }
                setEmail(value);
              }}
              value={email}
              isInvalid={emailInvalid}
            />
            <Input
              dataType={DataType.Password}
              label="Contraseña..."
              value={password}
              onChange={(value) => {
                if (value && value.trim() !== "") {
                  setPasswordInvalid(false);
                }
                setPassword(value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  loginAction();
                }
              }}
              isInvalid={passwordInvalid}
            />
            <Button variant="dark" onClick={loginAction}>
              Iniciar Sesión
            </Button>
            {errorInLogin && (
              <Alert variant={"danger"}>
                Hubo un problema al iniciar sesión.
              </Alert>
            )}
          </Stack>
        </Form>
      </CardBody>
    </Card>
  );
};

export default LoginPage;
