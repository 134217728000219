import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { User, ClientType, EntityType } from "../types";
import { getList } from "../components/djangoHelpers";
import { useCurrentUserContext } from "./currentUserContext";

interface AdminContextInterface {
  users: User[];
  setUsers: React.Dispatch<User[]>;
  reloadUsers: () => void;
  clientTypes: ClientType[];
  setClientTypes: React.Dispatch<ClientType[]>;
  reloadClientTypes: () => void;
}

const AdminContext = React.createContext<AdminContextInterface | null>(null);

function AdminProvider({ children }: { children: React.ReactNode }) {
  const { isStaff } = useCurrentUserContext();

  if (!isStaff) {
    throw new Error("Only staff users are allowed");
  }

  const [users, setUsers] = useState<User[]>([]);
  const [clientTypes, setClientTypes] = useState<ClientType[]>([]);

  const reloadUsers = useCallback(async () => {
    const userData = await getList<User>(
      EntityType.Users,
      false,
      false,
      undefined
    );
    setUsers(userData);
  }, []);

  const reloadClientTypes = useCallback(async () => {
    const clientTypesData = await getList<ClientType>(
      EntityType.ClientTypes,
      false,
      false,
      undefined
    );
    setClientTypes(clientTypesData);
  }, []);
  useEffect(() => {
    reloadUsers();
    reloadClientTypes();
  }, [reloadClientTypes, reloadUsers]);

  const value = useMemo<AdminContextInterface>(
    () => ({
      users,
      setUsers,
      reloadUsers,
      clientTypes,
      setClientTypes,
      reloadClientTypes,
    }),
    [users, reloadUsers, clientTypes, reloadClientTypes]
  );

  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
}

export const useAdminContext = () => {
  return useContext(AdminContext) as AdminContextInterface;
};

export default AdminProvider;
