import { DetCart } from "../../../types";

const CartDetailComment = ({ cart }: { cart: DetCart }) => {
  return (
    <p className="lead">
      <span>Anotaciones :</span>
      <span>{cart.comments || "Esta cotización no tiene anotaciones. "}</span>
    </p>
  );
};

export default CartDetailComment;
