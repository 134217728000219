import React, { useMemo } from "react";
import { Entity } from "../../../types";
import { InputProps } from "./../Input";
import SelectInput from "./SelectInput";
import { SelectInputOption } from "../types";
import { useAdminContext } from "../../../contexts/AdminContext";

const ClientTypeInput = <M extends Entity>(props: InputProps<M>) => {
  const { clientTypes } = useAdminContext();

  const clientTypeOptions = useMemo<SelectInputOption[]>(
    () =>
      clientTypes.map((clientType) => ({
        value: clientType.id,
        label: clientType.name,
      })),
    [clientTypes]
  );

  return <SelectInput {...props} options={clientTypeOptions} />;
};

export default ClientTypeInput;
