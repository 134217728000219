import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Page } from "../../constants";
import BrandsGrid from "../Brands/BrandsGrid";

const DashboardBrandsSection = () => {
  return (
    <Container>
      <div className="d-flex justify-content-center mt-4">
        <Link
          style={{ margin: "30px auto" }}
          to={`/${Page.Products}`}
          className="btn btn-info"
          role="button"
        >
          Ver Nuestros Productos
        </Link>
      </div>

      <Row className="mt-4">
        <h4>o buscar por Marca</h4>
      </Row>

      <BrandsGrid />
    </Container>
  );
};

export default DashboardBrandsSection;
