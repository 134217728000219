import { Button, Modal } from "react-bootstrap";
import { useCallback, useState } from "react";
import { EntityType, Entity } from "../../types";
import { patchOne } from "../djangoHelpers";
import Input, { DataType } from "../Editors/Input";

const EditPropertyModal = <E extends Entity>({
  modalTitle,
  hideModal,
  propertiesToEdit,
  entityType,
  entityId,
  existingValue,
  onComplete,
  isOpen,
}: {
  isOpen: boolean;
  modalTitle: string;
  hideModal: () => void;
  propertiesToEdit: Array<{
    property: string;
    label: string;
    dataType: DataType;
  }>;
  entityType: EntityType;
  entityId: number;
  existingValue: Partial<E> | undefined;
  onComplete?: (resData: E) => void;
}) => {
  const [object, setObject] = useState<Partial<Entity>>(existingValue || {});
  const [changesMade, setChangesMade] = useState(false);

  const updateProperty = useCallback((property: string, value: string) => {
    setChangesMade(true);
    setObject((o) => ({ ...o, [property]: value }));
  }, []);

  const saveChanges = useCallback(
    // TODO: fix ts ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (!e.target) {
        return;
      }

      patchOne(entityType, entityId, object).then(
        (resData) => onComplete && onComplete(resData)
      );

      hideModal();
    },
    [entityId, entityType, hideModal, object, onComplete]
  );

  return (
    <Modal
      show={isOpen}
      onHide={hideModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {propertiesToEdit.map((propertyToEdit) => (
          <Input
            dataType={propertyToEdit.dataType}
            label={propertyToEdit.label}
            key={propertyToEdit.property}
            onChange={(e) => {
              updateProperty(propertyToEdit.property, e.target.value);
            }}
            value={object[propertyToEdit.property as keyof object] || ""}
          />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="submit" onClick={hideModal}>
          Cerrar
        </Button>
        <Button variant="dark" onClick={saveChanges} disabled={!changesMade}>
          Guardar Cambios
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPropertyModal;
