import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Entity, Filter, PaginatedData } from "../types";
import { convertFilterListToFilterString } from "../utils";
import { PaginatedEntityType, getList } from "../components/djangoHelpers";

export enum EntityDisplayType {
  List = "List",
  Grid = "Grid",
}

interface EntityListContextInterface<M extends Entity> {
  entityType: PaginatedEntityType;
  paginatedEntityData: PaginatedData<M> | undefined;
  getPaginatedEntityData: (query?: string) => void;
  displayType: EntityDisplayType;
  switchDisplayType: (displayType: EntityDisplayType) => void;
}

const EntityListContext =
  React.createContext<EntityListContextInterface<Entity> | null>(null);

function EntityListProvider<M extends Entity>({
  entityType,
  children,
  staticFilters,
}: {
  entityType: PaginatedEntityType;
  children: React.ReactNode;
  staticFilters?: Filter[];
}) {
  const [paginatedEntityData, setPaginatedEntityData] = useState<
    PaginatedData<M> | undefined
  >(undefined);

  const [displayType, setDispalyType] = useState(EntityDisplayType.List);
  const toggleDisplayType = useCallback(() => {
    if (displayType === EntityDisplayType.List) {
      setDispalyType(EntityDisplayType.Grid);
    } else {
      setDispalyType(EntityDisplayType.List);
    }
  }, [displayType]);

  const getPaginatedEntityData = useCallback(
    (query?: string) => {
      let completeQuery = query || "";
      if (staticFilters) {
        const staticFilterString =
          convertFilterListToFilterString(staticFilters);
        if (completeQuery !== "") {
          completeQuery += "&" + staticFilterString;
        } else {
          completeQuery = staticFilterString;
        }
      }

      getList<M>(entityType, true, false, completeQuery).then((data) =>
        setPaginatedEntityData(data)
      );
    },
    [entityType, staticFilters]
  );

  useEffect(() => {
    getPaginatedEntityData();
  }, [getPaginatedEntityData]);

  const value = useMemo<EntityListContextInterface<M>>(
    () => ({
      entityType,
      paginatedEntityData,
      getPaginatedEntityData,
      displayType,
      switchDisplayType: toggleDisplayType,
    }),
    [
      entityType,
      displayType,
      getPaginatedEntityData,
      paginatedEntityData,
      toggleDisplayType,
    ]
  );

  return (
    <EntityListContext.Provider value={value}>
      {children}
    </EntityListContext.Provider>
  );
}

export const useEntityListContext = <M extends Entity>() => {
  return useContext(EntityListContext) as EntityListContextInterface<M>;
};

export default EntityListProvider;
