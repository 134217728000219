import { Form } from "react-bootstrap";
import { Entity } from "../../../types";
import { InputProps } from "./../Input";
import { SelectInputOption } from "../types";
import { useCallback, useRef } from "react";

interface SelectInputProps<M extends Entity> extends InputProps<M> {
  options: SelectInputOption[];
  multiple?: boolean;
}

const SelectInput = <M extends Entity>({
  value,
  options,
  onChange,
  multiple,
}: SelectInputProps<M>) => {
  const inputRef = useRef<HTMLSelectElement>(null);

  const onChangeHelper = useCallback(
    // TODO: TYPE INPUT EVENTS!
    (event: {
      target:
        | undefined
        | {
            value: number;
          };
    }) => {
      if (multiple) {
        if (!inputRef.current) {
          return;
        }
        onChange(inputRef.current.value.split(","));
      }

      const value = event.target?.value;
      if (value && !isNaN(value)) {
      // TODO: fix type mess
        onChange(parseInt(value as unknown as string));
        return;
      }

      onChange(value);
    },
    [multiple, onChange]
  );

  return (
    <Form.Control
      value={value || -1}
      as="select"
      // TODO:ts error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={onChangeHelper}
      multiple={multiple}
      ref={inputRef}
    >
      <option value={-1} disabled>
        Seleccionar...
      </option>
      {options.length === 0 ? (
        <option disabled>Cargando...</option>
      ) : (
        options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))
      )}
    </Form.Control>
  );
};

export default SelectInput;
