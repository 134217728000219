import { useCallback, useMemo } from "react";
import { ListGroup } from "react-bootstrap";
import { EntityType, Entity, User } from "../../../types";

const getEntityIdentifier = <M extends Entity>(
  entity: M,
  entityType: EntityType
) => {
  switch (entityType) {
    case (EntityType.Users): {
      const user = entity as unknown as User
      return `${user.first_name} ${user.last_name}`
    }
    default:
      if ("name" in entity) {
        return entity.name as string;
      }
      throw new Error("No entity identifier for entitytype: " + entityType);
  }
};

const EntityEditorEntityListEntry = <M extends Entity>({
  entity,
  entityType,
  setCurrentEntity,
}: {
  entity: M;
  entityType: EntityType;
  setCurrentEntity: React.Dispatch<React.SetStateAction<M | undefined>>;
}) => {
  const openEntityData = useCallback(() => {
    setCurrentEntity(entity);
  }, [entity, setCurrentEntity]);

  const label = useMemo(
    () => getEntityIdentifier(entity, entityType),
    [entity, entityType]
  );

  return (
    <ListGroup.Item key={entity.id} action onClick={openEntityData}>
      {label}
    </ListGroup.Item>
  );
};

export default EntityEditorEntityListEntry;
