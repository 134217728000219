import React from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { FaMinusCircle } from "react-icons/fa";
import {
  DetCartKitEntry,
  DetCart,
  CartProductQuantity,
  DetKitKitEntry,
} from "../../../types";
import { PRICE_TYPE_LIST, PriceType } from "../../../constants";

const CartDetailTableKit = ({
  entry,
  cart,
  removeKitEntryFromCart,
  changeKitPriceType,
  updateCartKitEntryQuantity,
}: {
  entry: DetCartKitEntry;
  cart: DetCart;
  removeKitEntryFromCart: () => void;
  changeKitPriceType: (priceTypeId: number | null) => void;
  updateCartKitEntryQuantity: (quantity: number) => void;
}) => {
  // TODO: need to get selected products from kitSelectableProductEntry
  const getChildKitsInfo = (
    kitEntries: DetKitKitEntry[],
    childKitsPrice: number,
    treeQuantity: number,
    contentsSoFar: CartProductQuantity[] = []
  ) => {
    kitEntries.forEach((kitEntry) => {
      const kitQuantity = kitEntry.quantity * treeQuantity;
      const kit = kitEntry.childKit;

      kit.kitProducts.forEach((productEntry) => {
        const quantity = productEntry.quantity * kitQuantity;
        const product = productEntry.childProduct;

        const contentsSoFarIndex = contentsSoFar.findIndex(
          (x) => x.product.id === productEntry.childProduct.id
        );
        if (contentsSoFarIndex >= 0) {
          contentsSoFar[contentsSoFarIndex].quantity += quantity;
        } else {
          contentsSoFar.push({
            quantity,
            product,
          });
        }
      });
      getChildKitsInfo(
        kit.kitKits,
        childKitsPrice,
        treeQuantity * kitQuantity,
        contentsSoFar
      );
    });

    return contentsSoFar;
  };

  const appendKitPriceTypeDropdown = (entry: DetCartKitEntry) => {
    const clientType = cart.client.type;
    const specifiedPriceType = entry.specifiedPriceType
      ? entry.specifiedPriceType
      : undefined;

    const basePriceType = clientType?.basePriceType || PriceType.One;

    return (
      <Form.Control
        as="select"
        onChange={(e) => {
          changeKitPriceType(Number(e.target.value));
        }}
      >
        {PRICE_TYPE_LIST.map((priceType) => (
          <option
            selected={
              specifiedPriceType
                ? specifiedPriceType === priceType
                : // TODO: is this needed?
                  // brandPrice ? brandPrice.priceType === priceType.id :
                  priceType === basePriceType
            }
            value={priceType}
            key={priceType}
          >
            Precio: {priceType}
          </option>
        ))}
      </Form.Control>
    );
  };

  const contentDescriptions: Array<{ id: number; text: string }> = [];
  const raw: CartProductQuantity[] = [];

  entry.kit.kitProducts.forEach((productEntry) => {
    const quantity = productEntry.quantity;
    const product = productEntry.childProduct;

    const rawIndex = raw.findIndex(
      (x) => x.product.id === productEntry.childProduct.id
    );
    if (rawIndex >= 0) {
      raw[rawIndex].quantity += quantity;
    } else {
      raw.push({
        quantity,
        product,
      });
    }
  });

  // TODO: make sure this is working
  entry.cartSelectableProductKitEntryEntries.forEach((y) => {
    const product = y.product;
    const quantity = y.selectableProductKitEntry.quantity;

    const rawIndex = raw.findIndex((x) => x.product.id === y.product.id);

    if (rawIndex >= 0) {
      raw[rawIndex].quantity += quantity;
    } else {
      raw.push({
        quantity,
        product,
      });
    }
  });

  // TODO: cart kit entry does not update with selected prices
  getChildKitsInfo(entry.kit.kitKits, 0, 1, raw).forEach((content) => {
    contentDescriptions.push({
      id: content.product.id,
      text:
        content.quantity +
        "x " +
        content.product.name +
        " " +
        content.product.productPrice?.peso_price +
        "\n",
    });
  });

  return (
    <tr className={entry.specifiedPriceType ? "table-warning" : ""}>
      <td className="table-cell-count">
        <InputGroup className="mb-3">
          <Button
            className="button"
            variant="outline-secondary"
            onClick={() => {
              updateCartKitEntryQuantity(entry.quantity - 1);
            }}
          >
            -
          </Button>
          <FormControl
            defaultValue="0"
            type="number"
            min="0"
            step="1"
            value={entry.quantity}
            className="input"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              updateCartKitEntryQuantity(Number(e.target.value));
            }}
          />
          <Button
            className="button"
            variant="outline-secondary"
            onClick={() => {
              updateCartKitEntryQuantity(entry.quantity + 1);
            }}
          >
            +
          </Button>
        </InputGroup>
      </td>
      <td>{entry.kit.name}</td>
      <td>
        {appendKitPriceTypeDropdown(entry)}
        {entry.specifiedPriceType && (
          <div
            onClick={() => {
              changeKitPriceType(null);
            }}
          >
            reset
          </div>
        )}
      </td>
      <td>
        {contentDescriptions.map((content) => (
          <p key={content.id}>{content.text}</p>
        ))}
      </td>
      <td>{entry.kit.total_price}</td>
      {/* TODO: show price including quantity */}
      {/* <td>{decoratePrice(entry.quantity * kitPriceInPesos)}</td> */}
      <td
        onClick={() => {
          removeKitEntryFromCart();
        }}
        className="tableCell-remove"
      >
        <FaMinusCircle />
      </td>
    </tr>
  );
};

export default CartDetailTableKit;
