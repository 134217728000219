import { PropsWithChildren } from "react";
import { Container, Row } from "react-bootstrap";

interface Props extends PropsWithChildren {
  stepNumber: number;
  title: string;
  description: React.ReactNode;
}

const TutorialRow = ({ stepNumber, title, description, children }: Props) => {
  return (
    <div className={'jumbotron mb-4'}>
        <Row>
          <h3 className=" pb-2" style={{borderBottom: '3px solid #d9534f'}}>
            {stepNumber}: {title}
          </h3>
        </Row>
        <Row className="text-dark">{description}</Row>
      <Container>{children}</Container>
    </div>
  );
};

export default TutorialRow;
