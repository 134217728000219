// TODO: FIX ANY TYPES
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { DetCartProductEntry, Entity } from "../../types";
import ClientTypeInput from "./Implementations/ClientTypeInput";
import NumberInput from "./Implementations/NumberInput";
import PriceTypeInput from "./Implementations/PriceTypeInput";
import StringInput from "./Implementations/StringInput";
import BrandInput from "./Implementations/BrandInput";
import BrandPriceInput from "./Implementations/BrandPriceInput/BrandPriceInput";
import ProductPriceTypeInput from "./Implementations/ProductPriceTypeInput";
import UserInput from "./Implementations/UserInput";
import KitProductEntryInput from "./Implementations/KitProductEntryInput";

export enum DataType {
  Brand = "Brand",
  BrandPrices = "BrandPrices",
  ClientType = "ClientType",
  User = "User",
  Seller = "Seller",
  Email = "Email",
  Password = "Password",
  Int = "Int",
  PriceType = "PriceType",
  String = "String",
  ProductPriceType = "ProductPriceType",
  ProductPrice = "ProductPrice",
  SelectableProductKitEntry = "SelectableProductKitEntry",
  KitProductEntry = "KitProductEntry",
  KitKitEntry = "KitKitEntry",
  KitSelectableProductEntry = "KitSelectableProductEntry",
}

export type OnChangeFunction = (newValue: any) => void;

export interface InputProps<M extends Entity> {
  onChange: OnChangeFunction;
  value: any;
  entity?: M;
  label?: string;
  onCommit?: (newValue: any) => void;
  isInvalid?: boolean;
  invalidMessage?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface InputRouterProps<M extends Entity> extends InputProps<M> {
  dataType: DataType;
}

const InputRouter = <M extends Entity>(props: InputRouterProps<M>) => {
  switch (props.dataType) {
    case DataType.ClientType:
      return <ClientTypeInput {...props} />;
    case DataType.Int:
      return <NumberInput {...props} />;
    case DataType.Email:
      return <StringInput type="email" {...props} />;
    case DataType.String:
      return <StringInput {...props} />;
    case DataType.PriceType:
      return <PriceTypeInput {...props} />;
    case DataType.Brand:
      return <BrandInput {...props} />;

    case DataType.Password:
      return <StringInput type={"password"} {...props} />;
    case DataType.User:
    case DataType.Seller:
      return (
        <UserInput {...props} staff={props.dataType === DataType.Seller} />
      );

    default:
      break;
  }

  const entity = props.entity;

  // if (!entity) {
  //   throw new Error(
  //     `${DataType.ProductPriceType} Input needs an existing entity`
  //   );
  // }

  // inputs dependent on defined entity
  switch (props.dataType) {
    case DataType.ProductPriceType:
      return (
        <ProductPriceTypeInput
          {...props}
          // TODO: fix this
          // @ts-expect-error what is going on here
          entity={entity as unknown as DetCartProductEntry}
        />
      );

    case DataType.BrandPrices:
      return <BrandPriceInput {...props} entity={entity!} />;

    case DataType.KitProductEntry:
      // TODO:
      return <KitProductEntryInput {...props} />;

    // case DataType.KitKitEntry:
    //   // TODO:
    //   return <BrandPriceInput {...props} />;

    // case DataType.KitSelectableProductEntry:
    //   // TODO:
    //   return <BrandPriceInput {...props} />;

    default:
      break;
  }

  throw new Error(`No inputs found for data type: ${props.dataType}`);
};

const Input = <M extends Entity>(props: InputRouterProps<M>) => {
  const onChangeHelper = useCallback(
    (value: string | number) => {
      props.onChange(value);
    },
    [props]
  );

  return (
    <Form.Group>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <InputGroup>
        <InputRouter {...props} onChange={onChangeHelper} />
        {props.invalidMessage && (
          <Form.Control.Feedback type="invalid">
            {props.invalidMessage}
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </Form.Group>
  );
};

export default Input;
