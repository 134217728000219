import React, { useMemo } from "react";
import { Pagination } from "react-bootstrap";
import { Entity, PaginatedData } from "../../types";
import { PAGINATION_COUNT } from "./../../constants";

const NUMBER_OF_PAGES = 6;

const PaginationBar = ({
  response,
  currentPage,
  setCurrentPage,
}: {
  response: PaginatedData<Entity>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const pageCount = useMemo(
    () => Math.ceil(response.count / PAGINATION_COUNT) + 1,
    [response.count]
  );

  const pages = useMemo(() => {
    let start = currentPage - NUMBER_OF_PAGES / 2;
    if (start < 1) start = 1;
    let end = start + NUMBER_OF_PAGES;
    if (end > pageCount) end = pageCount;
    const pages = [];
    for (let i = start; i < end; i++) {
      pages.push(i);
    }
    return pages;
  }, [pageCount, currentPage]);

  return (
    <Pagination style={{ margin: "0 auto" }}>
      <Pagination.Item onClick={() => setCurrentPage(1)}>‹‹</Pagination.Item>
      <Pagination.Item
        disabled={!response.previous}
        onClick={() => response.previous && setCurrentPage(currentPage - 1)}
      >
        ‹
      </Pagination.Item>

      {pages[0] !== 1 && (
        <Pagination.Item key={`more_before`} disabled={true}>
          ...
        </Pagination.Item>
      )}

      {pages.map((pageNum) => (
        <Pagination.Item
          key={`${pageNum}page`}
          active={pageNum === currentPage}
          onClick={() => !(pageNum === currentPage) && setCurrentPage(pageNum)}
          activeLabel={""}
        >
          {pageNum}
        </Pagination.Item>
      ))}
      {pages[pages.length - 1] !== pageCount - 1 && (
        <Pagination.Item key={`more_after`} disabled={true}>
          ...
        </Pagination.Item>
      )}

      <Pagination.Item
        key="nextPage"
        disabled={!response.next}
        onClick={() => response.next && setCurrentPage(currentPage + 1)}
      >
        ›
      </Pagination.Item>
      <Pagination.Item onClick={() => setCurrentPage(pageCount - 1)}>
        ››
      </Pagination.Item>
    </Pagination>
  );
};

export default PaginationBar;
