import { useState, useEffect } from "react";
import { InputGroup, Dropdown, FormControl } from "react-bootstrap";
import { DisplayedField, FilterType } from "../../types";
import PaginationBar from "../Ui/PaginationBar";
import {
  EntityDisplayType,
  useEntityListContext,
} from "../../contexts/entityListContetxt";
import PaginatedTable from "./PaginatedTable";

const PaginationContainer = ({
  filterTypes,
  displayedFields,
}: {
  filterTypes: FilterType[];
  displayedFields: DisplayedField[];
}) => {
  const {
    entityType,
    paginatedEntityData,
    getPaginatedEntityData,
    displayType,
  } =
    // TODO: add entity type
    useEntityListContext();

  const [filterType, setFilterType] = useState<FilterType>(filterTypes[0]);
  const [filterString, setFilterString] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    // build GET request url based on current page and filter fields
    const query = `${filterType.value}=${filterString}&page=${currentPage}`;
    getPaginatedEntityData(query);
  }, [currentPage, filterString, filterType.value, getPaginatedEntityData]);

  return (
    <>
      
      <div className="row">
        <InputGroup className="mb-3">
          <Dropdown>
            <Dropdown.Toggle variant="dark" id="dropdown-basic">
              {filterType.name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {filterTypes.map((_filterType) => (
                <Dropdown.Item
                  key={_filterType.value}
                  onSelect={() => setFilterType(_filterType)}
                >
                  {_filterType.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <FormControl
            onChange={(e) => {
              setFilterString(e.target.value);
            }}
            placeholder="Buscar..."
            aria-label="Buscar..."
            aria-describedby="button-addon"
          />
        </InputGroup>
      </div>

      {paginatedEntityData && (
        <>
          <div className="row">
            {displayType === EntityDisplayType.List ? (
              <PaginatedTable
                entities={paginatedEntityData.results}
                entityType={entityType}
                displayedFields={displayedFields}
              />
            ) : (
              // TODO: generic grid layout
              <>grid view</>
            )}
          </div>

          <div className="row">
            {paginatedEntityData && (
              <PaginationBar
                response={paginatedEntityData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PaginationContainer;
