import React, { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import CSVReader from "react-csv-reader";
import { uploadCsv, uploadKitCsv } from "../djangoHelpers";

const CsvUpload = () => {
  const [csvRows, setCsvRows] = useState<Array<string>>([]);

  const onFileLoaded = async (uploadedCsvRows: Array<string>) => {
    console.log(uploadedCsvRows);
    setCsvRows(uploadedCsvRows);
  };

  const upload = useCallback(() => {
    uploadCsv(csvRows);
  }, [csvRows]);

  const uploadKits = useCallback(() => {
    uploadKitCsv(csvRows);
  }, [csvRows]);

  return (
    <div>
      <CSVReader
        cssInputClass="btn btn-info btn-block"
        onFileLoaded={(data) => onFileLoaded(data)}
      />

      <Button disabled={csvRows.length === 0} onClick={upload}>
        Subir Productos
      </Button>

      <Button disabled={csvRows.length === 0} onClick={uploadKits}>
        Subir Kits
      </Button>
      {/* <ProgressBar animated now={progress} /> */}

      {/* <div>
			{infoStrings.current.map((infoString, i) => <p key='i'>
				{infoString}
			</p>)}
		</div> */}
    </div>
  );
};

export default CsvUpload;
