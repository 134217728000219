import { EntityType, ProductPrice } from "./types";
import { CART_ENTRY_ENTITY_TYPES, Currency } from "./constants";

const exchangeRate = 18;

// TODO: should this be done in the backend?
export function getPesoPrice(productPrice: ProductPrice, quantity: number = 1) {
  const { price, currency, decimals } = productPrice;
  const floatPrice = (price * quantity) / Math.pow(10, decimals);
  if (currency === Currency.USD) {
    return floatPrice * exchangeRate;
  }
  return floatPrice;
}

export function decoratePrice(price: number) {
  return (
    "$" +
    price
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
}

export function parsePrice(productPrice: ProductPrice, quantity: number = 1) {
  const priceInPesos = getPesoPrice(productPrice, quantity);
  return decoratePrice(priceInPesos);
}

export const checkEntryEntities = (entityType: EntityType) => {
  if (!CART_ENTRY_ENTITY_TYPES.includes(entityType))
    throw new Error(
      `Unidentified entityType! entityType "${entityType}" has to be part of ${CART_ENTRY_ENTITY_TYPES.toString()}`
    );
};
