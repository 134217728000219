import { useMemo } from "react";
import {
  DetCart,
  DetCartKitEntry,
  DetCartProductEntry,
  EntityType,
} from "../../../types";
import { useStoreContext } from "../../../contexts/storeContext";

const ENTITY_TYPE_TO_CART_ENTRY_PROPERTY_NAME: Partial<
  Record<EntityType, keyof DetCart>
> = {
  [EntityType.Kits]: "cartKitEntries",
  [EntityType.Products]: "cartProductEntries",
};

const useCartEntry = <E extends DetCartProductEntry | DetCartKitEntry>(
  entityType: EntityType,
  entityId: number
) => {
  const { activeCart } = useStoreContext();

  const cartEntryProperty = ENTITY_TYPE_TO_CART_ENTRY_PROPERTY_NAME[entityType];

  if (!cartEntryProperty) {
    throw new Error(`No cart entry property defined for ${entityType}`);
  }

  const cartEntry = useMemo(() => {
    if (activeCart) {
      const cartEntryList = activeCart[cartEntryProperty] as unknown as E[];

      return cartEntryList.find((cartEntryItem) => {
        if ("product" in cartEntryItem) {
          return cartEntryItem.product.id === entityId;
        }

        if ("kit" in cartEntryItem) {
          return cartEntryItem.kit.id === entityId;
        }

        throw new Error("useCartEntry: Invalid data");
      });
    }
  }, [activeCart, cartEntryProperty, entityId]);

  return cartEntry;
};

export default useCartEntry;
