import { Link } from "react-router-dom";
import {
  DetCartKitEntry,
  DetCartProductEntry,
  DisplayedField,
  Entity,
} from "../../types";
import useCartEntry from "./hooks/useCartEntry";
import usePostEntryToCart from "./hooks/usePostEntryToCart";
import AddToCartButton from "./AddToCartButton";
import useDeleteEntryFromCart from "./hooks/useDeleteEntryFromCart";
import { getValueFromEntity } from "../../utils";
import { PaginatedEntityType } from "../djangoHelpers";
import Renderer from "../Renderers/Renderer";
import { ENTITY_TYPE_TO_PAGE } from "../../constants";

// TODO: RENAME
export type CartEntry = DetCartProductEntry | DetCartKitEntry;

// TODO: this should be a wrapper for table rows and grid card for products and kits
const PaginatedTableRow = ({
  entity,
  entityType,
  displayedFields,
}: {
  entity: Entity;
  entityType: PaginatedEntityType;
  displayedFields: DisplayedField[];
}) => {
  const cartEntry = useCartEntry(entityType, entity.id);

  const postEntryToCart = usePostEntryToCart(entityType, cartEntry, entity.id);
  const useDeleteEntityFromCart = useDeleteEntryFromCart(entityType, cartEntry);

  return (
    <tr key={entity.id}>
      <AddToCartButton
        entityType={entityType}
        cartEntry={cartEntry}
        postEntryToCart={postEntryToCart}
        deleteEntryFromCart={useDeleteEntityFromCart}
      />

      {displayedFields.map((displayedField) =>
        displayedField.isLink ? (
          <td key={displayedField.field}>
            <Link
              key={displayedField.field}
              className=""
              to={`/${ENTITY_TYPE_TO_PAGE[entityType]}/${entity.id}`}
            >
              {getValueFromEntity(entity, displayedField.field)}
            </Link>
          </td>
        ) : (
          <td key={displayedField.field}>
            <Renderer
              key={displayedField.field}
              dataType={displayedField.dataType}
              value={getValueFromEntity(entity, displayedField.field)}
            />
          </td>
        )
      )}
    </tr>
  );
};

export default PaginatedTableRow;
