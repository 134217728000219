import { PropsWithChildren, ReactNode } from "react";
import { Outlet } from "react-router-dom";

interface Props extends PropsWithChildren {
  button?: ReactNode;
}

const MainPageTemplate = ({ button, children }: Props) => {
  return (
    <div className="container">
      <div className="row mb-2">
        <div className="col-10"></div>
        <div className="col-2">{button && button}</div>
      </div>
      <div className="row">
        <Outlet />
        {children && children}
      </div>
    </div>
  );
};

export default MainPageTemplate;
