import { Badge } from "react-bootstrap";
import { CartStatus, StatusFrontendNames } from "../../constants";
import { useMemo } from "react";

const CartStatusRenderer = ({
  status,
  big,
}: {
  status: CartStatus;
  big?: boolean;
}) => {
  const color = useMemo(() => {
    switch (status) {
      case CartStatus.OPEN:
      case CartStatus.OPEN_PRIVATE:
        return "info";
      case CartStatus.PENDING:
        return "warning";
      case CartStatus.CLOSED:
        return "success";
    }
  }, [status]);

  if (big) {
    return <h2>
      <Badge bg={color}>{StatusFrontendNames[status]}</Badge>
    </h2>;
  }
  return <h5>
    <Badge bg={color}>{StatusFrontendNames[status]}</Badge>
  </h5>;
};

export default CartStatusRenderer;
