import React from "react";
import { DetProduct } from "../../../types";

const CartDetailTableCheckoutEntry = ({
  entry,
}: {
  // TODO: this type is used in various places, make exoprt
  entry: { product: DetProduct; quantity: number };
}) => {
  return (
    <tr>
      <td>{entry.quantity}</td>
      <td>{entry.product.sku}</td>
      <td>{entry.product.name}</td>
      <td>{entry.product.brand.name}</td>
      <td>{entry.product.productPrice?.peso_price}</td>
    </tr>
  );
};

export default CartDetailTableCheckoutEntry;
