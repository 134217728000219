import { ProductPrice } from "../../types";
import { DataType } from "../Editors/Input";
import ProductPriceRenderer from "./ProductPriceRenderer";

const Renderer = ({
  value,
  dataType,
}: {
  value: string | number | ProductPrice;
  dataType?: DataType;
}) => {
  switch (dataType) {
    case DataType.ProductPrice:
      return <ProductPriceRenderer productPrice={value as ProductPrice} />;

    default:
      return <div>{(value as string | number)}</div>;
  }
};

export default Renderer;
