import { useMemo } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { ENTITY_TYPE_TO_FRONTEND_NAME } from "../../../constants";
import { EntityType, Entity } from "../../../types";
import EntityEditorEntityListEntry from "./EntityEditorEntityListEntry";

const EntityEditorEntityList = <M extends Entity>({
  entityType,
  entityList,
  clearCurrentEntity,
  setCurrentEntity,
}: {
  entityType: EntityType;
  entityList: M[];
  clearCurrentEntity: () => void;
  setCurrentEntity: React.Dispatch<React.SetStateAction<M | undefined>>;
}) => {
  const frontendName = useMemo(
    () => ENTITY_TYPE_TO_FRONTEND_NAME[entityType],
    [entityType]
  );
  return (
    <div className="card text-white bg-primary mb-3">
      <div className="card-body">
        <h4 className="card-title text-white">{frontendName}s</h4>
      </div>
      <Button onClick={clearCurrentEntity} variant="info">
        Crear {frontendName}
      </Button>
      <ListGroup>
        {entityList.length !== 0 &&
          entityList.map((entity) => (
            <EntityEditorEntityListEntry<M>
              key={`admin-${entityType}-table-${entity.id}`}
              entityType={entityType}
              entity={entity}
              setCurrentEntity={setCurrentEntity}
            />
          ))}
      </ListGroup>
    </div>
  );
};

export default EntityEditorEntityList;
