import { useMemo } from "react";
import { User, EntityType, NewClient } from "../../../../types";
import { DataType } from "../../../Editors/Input";
import { Field } from "../EntityEditorEntityFieldInput";
import EntityEditorLayout from "../EntityEditorLayout";
import { useAdminContext } from "../../../../contexts/AdminContext";


const CLIENT_ENTITY_FIELDS: Field<User, NewClient>[] = [
  {
    id: "first_name",
    label: "Nombre",
    dataType: DataType.String,
  },
  {
    id: "last_name",
    label: "Apellido",
    dataType: DataType.String,
  },
  {
    id: "email",
    label: "Email",
    dataType: DataType.Email,
  },
  {
    id: "phone",
    label: "Teléfono",
    dataType: DataType.Int,
  },
  {
    id: "type",
    label: "Tipo de Cliente",
    dataType: DataType.ClientType,
    defaultValue: 1,
  },
  {
    id: "seller",
    label: "Vendedor",
    dataType: DataType.Seller,
  },
]

const ClientsEditor = () => {
  const { users, reloadUsers: reloadClients } = useAdminContext();

  const clients = useMemo(() => users.filter((u) => !u.is_staff), [users]);

  const newClientTemplate = useMemo<NewClient>(
    () => ({
      first_name: `client_${clients.length}`,
      last_name: `client_${clients.length}`,
      email: `a${clients.length}@r.com`,
      phone: clients.length,
      type: 1,
      seller: 3,
    }),
    [clients.length]
  );


  return (
    <EntityEditorLayout<User, NewClient>
      entityType={EntityType.Users}
      reloadEntities={reloadClients}
      newEntityTemplate={newClientTemplate}
      entityFields={CLIENT_ENTITY_FIELDS}
      entityList={clients}
    />
  );
};

export default ClientsEditor;
