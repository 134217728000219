import { Form } from "react-bootstrap";
import { Entity } from "../../../types";
import { InputProps } from "./../Input";

const NumberInput = <M extends Entity>(props: InputProps<M>) => {
  return (
    <Form.Control
      {...props}
      type="number"
      placeholder={props.label ? `${props.label}...` : props.label}
    />
  );
};

export default NumberInput;
