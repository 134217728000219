import { useCallback } from "react";
import { useStoreContext } from "../../../contexts/storeContext";
import { CartEntry } from "../PaginatedTableRow";
import { PaginatedEntityType, deleteOne } from "../../djangoHelpers";
import { EntityType } from "../../../types";

const useDeleteEntryFromCart = (
  entityType: PaginatedEntityType,
  // TODO: type
  cartEntry: CartEntry | undefined
) => {
  const { activeCart, reloadActiveCart } = useStoreContext();

  const cartEntryEntityType =
    entityType === EntityType.Kits
      ? EntityType.CartKitEntries
      : EntityType.CartProductEntries;

  const deleteEntryFromCart = useCallback(() => {
    if (!activeCart) {
      throw new Error(
        "No Active Cart: cannot remove kit if no cart is selected"
      );
    }

    if (!cartEntry) {
      throw new Error("NO EXISITNG CART_KIT_ENTRY TO REMOVE");
    }

    deleteOne(cartEntryEntityType, cartEntry.id).then(() => {
      reloadActiveCart();
    });
  }, [activeCart, cartEntry, cartEntryEntityType, reloadActiveCart]);

  return deleteEntryFromCart;
};

export default useDeleteEntryFromCart;
