import { DataType } from "./components/Editors/Input";
import { CartStatus, Currency, PriceType } from "./constants";

export type KV<type> = <K extends keyof type, V extends type[K]>(
  key: K,
  value: V
) => void;

/* eslint-disable spellcheck/spell-checker */
export enum EntityType {
  "Brands" = "brands",
  "Products" = "products",
  "ProductPrices" = "productprices",
  "Categories" = "categories",
  "ProductCategories" = "productCategories",
  "CategoryCategories" = "categoryCategories",
  "Kits" = "kits",
  "KitProductsEntries" = "kitproductsentries",
  "KitKitEntries" = "kitkitentries",
  "Carts" = "carts",
  "CartProductEntries" = "cartproductentries",
  "CartKitEntries" = "cartkitentries",
  "CartStatus" = "cartstatus",
  "ClientTypes" = "clienttypes",
  "ClientTypeBrandPrice" = "clienttypebrandprice",
  "Users" = "users",
  "Groups" = "groups",
}
// eslint-enable spellcheck/spell-checker

export type FlatProducts = Array<{ product: DetProduct; quantity: number }>;

export interface NewEntity {}
export interface Entity {
  id: number;
  url: string;
}

export interface Brand extends Entity {
  name: string;
}
export interface NewBrand extends NewEntity {
  name: string;
}

export interface Product extends Entity {
  sku: string;
  name: string;
  brand: number;
  productPrices: ProductPrice[];
}
export interface NewProduct extends NewEntity {
  sku: string;
  name: string;
  brand: number;
}

export interface DetProduct extends Entity {
  sku: string;
  name: string;
  brand: Brand;
  productPrices?: ProductPrice[];
  productPrice?: ProductPrice;
}

export interface Kit extends Entity {
  name: string;
  kitProducts: number[];
  kitKits: number[];
  kitSelectableProducts: number[];
}

export interface NewKit extends NewEntity {
  name: string;
  kitProducts: number[];
  kitKits: number[];
  kitSelectableProducts: number[];
}

export interface SelectableProductKitEntry extends Entity {
  parentKit: number;
  quantity: number;
  productOptions: number[];
  description: string;
}

export interface DetSelectableProductKitEntry extends Entity {
  parentKit: number;
  quantity: number;
  productOptions: DetProduct[];
  description: string;
}

export interface DetKit extends Entity {
  name: string;
  kitProducts: DetKitProductEntry[];
  kitKits: DetKitKitEntry[];
  total_price: string;
  kitSelectableProducts: DetSelectableProductKitEntry[];
  flat_products: {
    products: FlatProducts;
    selectable_product_entries: Array<{
      quantity: number;
      selectable_product_entry: DetSelectableProductKitEntry;
    }>;
  };
}

export interface KitKitEntry extends Entity {
  parentKit: number;
  childKit: number;
  quantity: number;
}
export interface DetKitKitEntry extends Entity {
  parentKit: number;
  childKit: DetKit;
  quantity: number;
}

export interface KitProductEntry extends Entity {
  parentKit: number;
  childProduct: number;
  quantity: number;
}
export interface DetKitProductEntry extends Entity {
  parentKit: Kit;
  childProduct: DetProduct;
  quantity: number;
}

export interface NewPriceType extends NewEntity {
  name: string;
}

export interface ClientType extends Entity {
  name: string;
  basePriceType: number;
  brandPrices: ClientTypeBrandPrice[];
}
export interface NewClientType extends NewEntity {
  name: string;
  basePriceType: number;
  brandPrices: number[];
}
export interface DetClientType extends Entity {
  name: string;
  basePriceType: number;
  brandPrices: ClientTypeBrandPrice[];
}

export interface CartProductEntry extends Entity {
  product: number;
  cart: number;
  quantity: number;
  specifiedPriceType: PriceType | null;
}
export interface NewCartProductEntry extends NewEntity {
  product: number;
  cart: number;
  quantity: number;
  specifiedPriceType: PriceType | null;
}
export interface DetCartProductEntry extends Entity {
  product: DetProduct;
  cart: Cart;
  quantity: number;
  specifiedPriceType: PriceType | null;
  peso_price: string;
}

export interface CartKitEntry extends Entity {
  kit: number;
  cart: number;
  quantity: number;
  specifiedPriceType: PriceType | null;
}
export interface NewCartKitEntry extends NewEntity {
  kit: number;
  cart: number;
  quantity: number;
  specifiedPriceType: PriceType | null;
}

export interface CartSelectableProductKitEntry extends Entity {
  cartKitEntry: number;
  product: number;
  SelectableProductKitEntry: number;
}

export interface DetCartSelectableProductKitEntry extends Entity {
  cartKitEntry: number;
  selectableProductKitEntry: SelectableProductKitEntry;
  product: DetProduct;
}

export interface DetCartKitEntry extends Entity {
  kit: DetKit;
  cart: number;
  quantity: number;
  specifiedPriceType: PriceType | null;
  cartSelectableProductKitEntryEntries: DetCartSelectableProductKitEntry[];
}

export interface Cart extends Entity {
  name: string;
  comments: string | null;
  created: string;
  modified: string;
  status: CartStatus;
  client: number;
  cartProductEntries: CartProductEntry[];
  cartKitEntries: CartKitEntry[];
  clientType: ClientType;
  flat_products: Product[];
  product_quantity: number;
  total_prices: {
    pre: string;
    iva: string;
    total: string;
  };
}

export interface NewCart extends NewEntity {
  name: string;
  client: number;
  status?: CartStatus;
  comments?: string;
}
export interface DetCart extends Entity {
  name: string;
  comments: string | null;
  status: CartStatus;
  client: DetClient;
  cartProductEntries: DetCartProductEntry[];
  cartKitEntries: DetCartKitEntry[];
  total_prices: {
    pre: string;
    iva: string;
    total: string;
  };
  flat_products: FlatProducts;
}

export interface User extends Entity {
  first_name: string;
  last_name: string;
  phone: number;
  email: string;
  type: number;
  seller: number;
  is_staff: boolean;
}

export interface NewClient extends NewEntity {
  first_name: string;
  last_name: string;
  phone: number;
  email: string;
  type: number;
  seller: number;
}

export interface DetClient extends Entity {
  first_name: string;
  last_name: string;
  phone: number;
  email: string;
  type: DetClientType | null;
}

export interface ProductPrice extends Entity {
  price: number;
  currency: Currency;
  type: PriceType;
  decimals: number;
  peso_cents: number;
  peso_price: string;
}

export interface NewProductPrice extends NewEntity {
  price: number;
  currency: Currency;
  type: number;
}

export interface ClientTypeBrandPrice extends Entity {
  clientType: number;
  brand: number;
  priceType: PriceType;
}

export interface NewClientTypeBrandPrice extends NewEntity {
  clientType: number;
  brand: number;
  priceType: PriceType;
}

export interface Category extends Entity {
  name: string;
}

export interface ProductCategory extends Entity {
  product: Product;
  category: Category;
}

export interface CategoryCategory extends Entity {
  parentCategory: Category;
  childCategory: Category;
}

// misc

export interface Filter {
  filter: string;
  value: string | number | boolean;
}

export interface FilterType {
  value: string;
  name: string;
}

export interface DisplayedField {
  field: string;
  label: string;
  isLink?: boolean;
  dataType?: DataType;
}

export interface PaginatedData<type> {
  count: number;
  next: string | null;
  previous: string | null;
  results: type[];
}

export interface AxiosData<type> {
  data: type[];
}

export interface CartProductQuantity {
  quantity: number;
  product: DetProduct;
  specifiedPriceType?: PriceType | null;
}

export type OnChangeEvent = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;
