import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { EntityType, KV, Entity, NewEntity } from "../../../types";
import EntityEditorEntityFieldInput, {
  Field,
} from "./EntityEditorEntityFieldInput";
import EntityEditorEntityList from "./EntityEditorEntityList";
import { patchOne, postOne, deleteOne } from "../../djangoHelpers";

const EntityEditorLayout = <M extends Entity, NM extends NewEntity>({
  entityType,
  reloadEntities,
  newEntityTemplate,
  entityFields,
  entityList,
}: {
  entityType: EntityType;
  reloadEntities: () => void;
  newEntityTemplate: NM;
  entityFields: Field<M, NM>[];
  entityList: M[];
}) => {
  const [currentEntity, setCurrentEntity] = useState<M | undefined>(undefined);
  const [newEntity, setNewEntity] = useState<NM>(newEntityTemplate);

  const entityFromStore = useMemo(() => {
    return entityList.find((entity) => entity.id === currentEntity?.id);
  }, [currentEntity?.id, entityList]);

  useEffect(() => {
    if (entityFromStore) {
      setCurrentEntity(entityFromStore);
    }
  }, [entityFromStore]);

  // TODO: delete this, new entity template should not be dynamic in prod
  useEffect(() => {
    setNewEntity(newEntityTemplate);
  }, [newEntityTemplate]);

  const clearCurrentEntity = useCallback(() => {
    setCurrentEntity(undefined);
  }, []);

  const updateEntityFields: KV<M | NM> = useCallback(
    (key, value) => {
      if (currentEntity?.id) {
        setCurrentEntity({ ...currentEntity, [key]: value });
      } else {
        setNewEntity({ ...newEntity, [key]: value });
      }
    },
    [currentEntity, newEntity]
  );

  const patchEntity = useCallback(async () => {
    if (!currentEntity) throw new Error("No current entity");

    const entityData = await patchOne(
      entityType,
      currentEntity.id,
      currentEntity
    );
    setCurrentEntity(entityData);
    reloadEntities();
  }, [currentEntity, entityType, reloadEntities]);

  const postEntity = useCallback(async () => {
    const entityData = await postOne<NM, M>(entityType, newEntity);
    setNewEntity(newEntityTemplate);
    setCurrentEntity(entityData);
    reloadEntities();
  }, [entityType, newEntity, newEntityTemplate, reloadEntities]);

  const deleteEntity = useCallback(async () => {
    if (!currentEntity) throw new Error("No current entity");

    await deleteOne(entityType, currentEntity.id);
    setCurrentEntity(undefined);
    reloadEntities();
  }, [currentEntity, entityType, reloadEntities]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-4 col-lg-3">
          <EntityEditorEntityList<M>
            clearCurrentEntity={clearCurrentEntity}
            setCurrentEntity={setCurrentEntity}
            entityType={entityType}
            entityList={entityList}
          />
        </div>
        <div className="col-sm-12 col-md-8 col-lg-9">
          <Form>
            <Button
              onClick={() => {
                currentEntity ? patchEntity() : postEntity();
              }}
              variant={currentEntity ? "success" : "info"}
            >
              {currentEntity ? `Guardar Cambios` : `Crear Cliente`}
            </Button>

            <Form.Group controlId="AdminClientFields">
              {entityFields.map((field) => (
                <EntityEditorEntityFieldInput<M, NM>
                  key={`${entityType}-field-table-input-${String(field.id)}`}
                  field={field}
                  updateEntityFields={updateEntityFields}
                  currentEntity={currentEntity}
                  newEntity={newEntity}
                />
              ))}
            </Form.Group>

            {currentEntity && (
              <Button
                onClick={() => {
                  deleteEntity();
                }}
                variant={`danger`}
              >
                Borrar Cliente
              </Button>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EntityEditorLayout;
