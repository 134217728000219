import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div>
      <Row className="my-4">
        <h2>404 Página No Encontrada</h2>
      </Row>
      <h5 className="my-4">
        ¡Ups! La página que estás buscando no se pudo encontrar. Es posible que
        haya sido eliminada, que su nombre haya cambiado o que no esté
        disponible temporalmente.
      </h5>
      <Link
        style={{ margin: "30px auto" }}
        to={`/`}
        className="btn btn-info"
        role="button"
      >
        Volver a la página principal
      </Link>
    </div>
  );
};

export default PageNotFound;
