import React from "react";
import { DisplayedField, EntityType } from "../../types";
import PaginationContainer from "../Generic/PaginationContainer";
import EntityListProvider from "../../contexts/entityListContetxt";

const KIT_FILTER_TYPES = [
  {
    value: "all",
    name: "Todo",
  },
  {
    value: "sku",
    name: "SKU",
  },
  {
    value: "name",
    name: "Nombre",
  },
  {
    value: "brand__name",
    name: "Marca",
  },
];

const KIT_DISPLAY_FIELD: DisplayedField[] = [
  { field: "name", label: "Nombre", isLink: true },
];

const KitList = () => {
  return (
    <div style={{ width: "100%" }}>
      <EntityListProvider entityType={EntityType.Kits}>
        <PaginationContainer
          filterTypes={KIT_FILTER_TYPES}
          displayedFields={KIT_DISPLAY_FIELD}
        />
      </EntityListProvider>
    </div>
  );
};

export default KitList;
