import { Modal, Button } from "react-bootstrap";
import { ENTITY_TYPE_TO_FRONTEND_NAME } from "../../constants";
import { EntityType } from "../../types";
import { useUiStore } from "../../store/uiStore";
import { deleteOne } from "../djangoHelpers";
import { useCallback } from "react";

const CartDeletionConfirmModal = () => {
  const cartToDeleteData = useUiStore((state) => state.cartToDeleteData);

  const uiStoreApi = useUiStore((state) => state.api);

  const close = useCallback(() => {
    uiStoreApi.setCartToDeleteData();
  }, [uiStoreApi]);

  const deleteCart = useCallback(() => {
    if (cartToDeleteData?.id) {
      deleteOne(EntityType.Carts, cartToDeleteData.id).then(() => {
        uiStoreApi.setRequestReload(true);
        close();
      });
    }
  }, [cartToDeleteData, close, uiStoreApi]);

  return (
    <Modal show={!!cartToDeleteData} onHide={close} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Eliminar {ENTITY_TYPE_TO_FRONTEND_NAME[EntityType.Carts]}
        </Modal.Title>
      </Modal.Header>
      {cartToDeleteData && (
        <Modal.Body>
          Quiere eliminar su {ENTITY_TYPE_TO_FRONTEND_NAME[EntityType.Carts]}: "
          {cartToDeleteData.name}
          "?
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button variant="secondary" type="submit" onClick={close}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={deleteCart}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CartDeletionConfirmModal;
