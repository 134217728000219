import React, { useCallback } from "react";
import { useStoreContext } from "../../../contexts/storeContext";
import { DetCart, EntityType } from "../../../types";
import CartDetailTableCheckoutEntry from "./CartDetailTableCheckoutEntry";
import CartDetailTableKit from "./CartDetailTableKit";
import CartDetailTableProduct from "./CartDetailTableProduct";
import {
  updateCartEntryQuantity,
  removeEntryFromCart,
  updateCartEntryPriceType,
} from "../../djangoHelpers";
import { useDetailViewContext } from "../../../contexts/DetailViewContext";

const CartDetailTable = ({ checkoutView }: { checkoutView: boolean }) => {
  const { entity: cart, setEntityData: setCartData } =
    useDetailViewContext<DetCart>();

  const { activeCart, reloadActiveCart } = useStoreContext();

  const updateCartEntryQuantityHelper = async (
    entityType: EntityType,
    entryId: number,
    quantity: number
  ) => {
    const cartData = await updateCartEntryQuantity(
      entityType,
      cart.id,
      entryId,
      quantity
    );
    setCartData(cartData);
    if (activeCart?.id === cart.id) {
      reloadActiveCart();
    }
  };

  const removeEntryFromCartHelper = async (
    entityType: EntityType.CartProductEntries | EntityType.CartKitEntries,
    entryId: number
  ) => {
    const cartData = await removeEntryFromCart(entityType, cart.id, entryId);
    setCartData(cartData);
    if (activeCart?.id === cart.id) {
      reloadActiveCart();
    }
  };

  const changePriceTypeHelper = useCallback(
    async (
      entityType: EntityType,
      entryId: number,
      priceTypeId: number | null
    ) => {
      const cartData = await updateCartEntryPriceType(
        entityType,
        cart.id,
        entryId,
        priceTypeId
      );

      setCartData(cartData);
      if (activeCart?.id === cart.id) {
        reloadActiveCart();
      }
    },
    [activeCart?.id, cart.id, reloadActiveCart, setCartData]
  );

  return (
    <div>
      <p className="lead">Productos</p>
      <table className="table black table-hover cart-table">
        <thead>
          <tr>
            <th scope="col" style={{ width: 0 }}>
              Cantidad
            </th>
            <th scope="col">SKU</th>
            <th scope="col">Nombre</th>
            <th scope="col">Marca</th>
            <th scope="col">P/U</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          {checkoutView ? (
            cart.flat_products.length === 0 ? (
              <tr>
                <td colSpan={6}>No Hay Productos</td>
              </tr>
            ) : (
              cart.flat_products.map((entry) => (
                <CartDetailTableCheckoutEntry
                  key={entry.product.id}
                  entry={entry}
                />
              ))
            )
          ) : cart.cartProductEntries.length === 0 ? (
            <tr>
              <td colSpan={6}>No Hay Productos</td>
            </tr>
          ) : (
            cart.cartProductEntries.map((cartEntry) => (
              <CartDetailTableProduct
                key={cartEntry.id}
                entry={cartEntry}
                removeProductEntryFromCart={() =>
                  removeEntryFromCartHelper(
                    EntityType.CartProductEntries,
                    cartEntry.id
                  )
                }
                changeProductPriceType={(priceTypeId: number | null) =>
                  changePriceTypeHelper(
                    EntityType.CartProductEntries,
                    cartEntry.id,
                    priceTypeId
                  )
                }
                updateCartProductEntryQuantity={(quantity: number) =>
                  updateCartEntryQuantityHelper(
                    EntityType.CartProductEntries,
                    cartEntry.id,
                    quantity
                  )
                }
                cart={cart}
              />
            ))
          )}
        </tbody>
      </table>
      {!checkoutView && (
        <div>
          <p className="lead">Kits</p>
          <table className="table black table-hover">
            <thead>
              <tr>
                <th scope="col">Cantidad</th>
                <th scope="col">Nombre</th>
                <th scope="col">Tipo Precio</th>
                <th scope="col">Contenidos</th>
                <th scope="col">P/K</th>
                {/* <th scope="col">Importe</th> */}
                <th scope="col" style={{ width: 0 }}>
                  Eliminar
                </th>
              </tr>
            </thead>
            <tbody>
              {cart.cartKitEntries.length === 0 && (
                <tr>
                  <td colSpan={6}>No Hay Kits</td>
                </tr>
              )}
              {cart.cartKitEntries.length > 0 &&
                cart.cartKitEntries.map((kitEntry) => (
                  <CartDetailTableKit
                    key={kitEntry.id}
                    entry={kitEntry}
                    cart={cart}
                    removeKitEntryFromCart={() =>
                      removeEntryFromCartHelper(
                        EntityType.CartKitEntries,
                        kitEntry.id
                      )
                    }
                    changeKitPriceType={(priceTypeId: number | null) =>
                      changePriceTypeHelper(
                        EntityType.CartKitEntries,
                        kitEntry.id,
                        priceTypeId
                      )
                    }
                    updateCartKitEntryQuantity={(quantity) =>
                      updateCartEntryQuantityHelper(
                        EntityType.CartKitEntries,
                        kitEntry.id,
                        quantity
                      )
                    }
                  />
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CartDetailTable;
