import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { Page } from "../../constants";
import { useStoreContext } from "../../contexts/storeContext";
import { useUiStore } from "../../store/uiStore";
import { useCurrentUserContext } from "../../contexts/currentUserContext";
import { useDataStore } from "../../store/dataStore";
import Stack from "../Ui/Stack";
import RecentCartsDropdownItems from "./RecentCartsDropdownItems";
// import { FaShoppingCart } from "react-icons/fa";
import { Image } from "react-bootstrap";
import { AdminRoutes } from "../Main/MainRoutes";

const NavBar = () => {
  const { logout, isStaff } = useCurrentUserContext();
  const { brands } = useDataStore();
  const { activeCart } = useStoreContext();
  const useStoreApi = useUiStore((state) => state.api);

  return (
    <Navbar
      className="justify-content-between"
      bg="dark"
      variant="dark"
      fixed="top"
    >
      <Navbar.Brand>
        <Link to={"/"} style={{ color: "inherit", textDecoration: "inherit" }}>
          <Image src="/img/logo_white.svg" style={{ width: 170 }} />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Link to={`/${Page.Products}`}>
            <li className="nav-item">
              <span className="nav-link">Productos</span>
            </li>
          </Link>

          <NavDropdown title="Marcas">
            {brands.map((brand) => (
              <Link
                key={brand.id}
                className="dropdown-item"
                to={`/${Page.Brands}/${brand.id}`}
              >
                {brand.name}
              </Link>
            ))}
          </NavDropdown>

          <Link to={`/${Page.Kits}`}>
            <li className="nav-item">
              <span className="nav-link">Kits</span>
            </li>
          </Link>

          <Link to={`/${Page.Carts}`}>
            <li className="nav-item">
              <span className="nav-link">Cotizaciones</span>
            </li>
          </Link>

          {isStaff && (
            <NavDropdown title="Admin">
              {/* TODO: make paths constant? */}
              <Link className="dropdown-item" to={`/${Page.Admin}/${AdminRoutes.ClientTypes}`}>
                Tipos de Clientes
              </Link>
              <Link className="dropdown-item" to={`/${Page.Admin}/${AdminRoutes.Clients}`}>
                Clientes
              </Link>
              {/* <Link className="dropdown-item" to={`/${Page.Admin}/${AdminRoutes.KitCreate}`}>
                Crear Kits
              </Link> */}
              <Link className="dropdown-item" to={`/${Page.Admin}/${AdminRoutes.CsvUpload}`}>
                File Upload
              </Link>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>

      <Stack row>
        <Dropdown as={ButtonGroup}>
          {activeCart === undefined && (
            <Button variant="warning">Sin Cotización</Button>
          )}
          {activeCart !== undefined && (
            <Link to={`/${Page.Carts}/` + activeCart.id}>
              <Button variant="secondary">
                {/* TODO: add some cart icon */}
                {/* <FaShoppingCart /> */}
                {activeCart.name}
              </Button>
            </Link>
          )}
          <Dropdown.Toggle
            split
            variant="secondary"
            id="dropdown-split-basic"
          />

          <Dropdown.Menu>
            <Dropdown.Item onClick={useStoreApi.toggleShowCartCreateModal}>
              Crear Cotización
            </Dropdown.Item>
            <Dropdown.Divider />
            <RecentCartsDropdownItems />
          </Dropdown.Menu>
        </Dropdown>

        <Button variant="secondary" onClick={logout}>
          Cerrar sesión
        </Button>
      </Stack>
    </Navbar>
  );
};

export default NavBar;
