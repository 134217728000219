import { DetKit } from "../../types";
import KitDetailProductEntry from "./KitDetailProductEntry";
import KitSelectableProductEntry from "./KitSelectableProductEntry";
import { x } from "./KitDetail";

const KitDetailKitEntry = ({
  kit,
  root,
  quantity = 1,
  selectableProductValues,
  setSelectableProductValues,
}: {
  kit: DetKit;
  root?: boolean;
  quantity?: number;
  selectableProductValues: x;
  setSelectableProductValues: (
    selectableProductEntryId: number,
    productId: number
  ) => void;
}) => {
  return (
    <div className="container">
      {!root && (
        <div className="d-flex flex-row">
          <div>{quantity}</div>
          <div className="pl-2">{kit.name}</div>
        </div>
      )}

      <div className="p-2">
        {kit.kitSelectableProducts.map((kitSelectableProductEntry) => (
          <KitSelectableProductEntry
            key={kitSelectableProductEntry.id}
            quantity={kitSelectableProductEntry.quantity}
            kitSelectableProductEntry={kitSelectableProductEntry}
            value={selectableProductValues[kitSelectableProductEntry.id]}
            setValue={setSelectableProductValues}
          />
        ))}

        {kit.kitProducts.map((kitProductEntry) => (
          <KitDetailProductEntry
            key={kitProductEntry.id}
            kitProductEntry={kitProductEntry}
          />
        ))}

        {kit.kitKits.map((kitKitEntry) => (
          <KitDetailKitEntry
            key={kitKitEntry.id}
            kit={kitKitEntry.childKit}
            quantity={kitKitEntry.quantity}
            selectableProductValues={selectableProductValues}
            setSelectableProductValues={setSelectableProductValues}
          />
        ))}
      </div>
    </div>
  );
};

export default KitDetailKitEntry;
