import { DetKitProductEntry } from "../../types";
import ProductPriceRenderer from "../Renderers/ProductPriceRenderer";

const KitDetailProductEntry = ({
  kitProductEntry,
}: {
  kitProductEntry: DetKitProductEntry;
}) => {
  return (
    <div key={kitProductEntry.id} className="d-flex flex-row">
      <div>{kitProductEntry.quantity}</div>
      <div className="pl-2">{kitProductEntry.childProduct.name}</div>
      {/* TODO: admin case? */}
      {kitProductEntry.childProduct.productPrice && (
        <ProductPriceRenderer
          productPrice={kitProductEntry.childProduct.productPrice}
        />
      )}
    </div>
  );
};

export default KitDetailProductEntry;
