import { useMemo } from "react";
import { DetSelectableProductKitEntry } from "../../../types";
import { InputProps } from "../Input";
import SelectInput from "./SelectInput";
import { SelectInputOption } from "../types";

interface Props extends InputProps<DetSelectableProductKitEntry> {
  selectableProductKitEntry: DetSelectableProductKitEntry;
}

const SelectableProductKitEntry = (props: Props) => {
  const productOptions = useMemo<SelectInputOption[]>(
    () =>
      props.selectableProductKitEntry.productOptions.map((product) => ({
        value: product.id,
        label: product.name,
      })),
    [props.selectableProductKitEntry.productOptions]
  );

  return <SelectInput {...props} options={productOptions} />;
};

export default SelectableProductKitEntry;
